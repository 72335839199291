<template>
    <div class="row mb-4">
        <div class="col-md-12">
            <div class="card mb-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="name" class="form-label">{{ $t('components.user.name') }} <span class="badge badge-danger">{{ $t('components.required') }}</span></label>
                            <input type="text" class="form-control" :class="{ 'is-invalid': $v.name.$dirty && $v.name.$invalid }" @input="$v.name.$touch()" id="name" v-model="name">
                            <div v-if="$v.name.$dirty && !$v.name.required" class="invalid-feedback">
                                {{ $t('error.name') }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="email" class="form-label">{{ $t('components.user.email') }}</label>
                            <input type="email" class="form-control" :class="{ 'is-invalid': $v.email.$dirty && $v.email.$invalid }" @input="$v.email.$touch()" id="email" v-model="email">
                            <div v-if="$v.email.$dirty && !$v.email.email" class="invalid-feedback">
                                {{ $t('error.email_not_valid') }}
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label for="tel" class="form-label">{{ $t('components.user.phone') }}</label>
                            <input type="text" class="form-control" id="tel" v-model="tel">
                        </div>
                        <div class="col-md-6">
                            <label for="cell" class="form-label">{{ $t('components.user.cell') }}</label>
                            <input type="text" class="form-control" id="cell" v-model="cell">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-4">
                            <label for="username" class="form-label">{{ $t('components.user.username') }}</label>
                            <input type="text" class="form-control" id="username" v-model="username">
                        </div>
                        <div v-if="!userId" class="col-md-4">
                            <label for="password" class="form-label">{{ $t('components.user.password') }}</label>
                            <input type="password" class="form-control" id="password" v-model="password">
                        </div>
                        <div class="col-md-4">
                            <label for="role" class="form-label">{{ $t('components.user.role') }} <span class="badge badge-danger">{{ $t('components.required') }}</span></label>
                            <vselect :items="roleSelect" :options="roleSelect"  :class="{ 'is-invalid': $v.role.$dirty && $v.role.$invalid }" item-text="text" item-value="key" v-model="role" id="role" @input="$v.role.$touch" placeholder="Ruolo utente..."></vselect>
                            <div v-if="$v.role.$dirty && !$v.role.required" class="invalid-feedback">
                                {{ $t('error.role_required') }}
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <button v-if="userId" class="btn btn-warning btn-block" @click.prevent="submitUpdate" :disabled="$v.$invalid">{{ $t('components.user.update_button') }}</button>
                            <button v-else class="btn btn-success btn-block" @click.prevent="submitNew" :disabled="$v.$invalid">{{ $t('components.user.create_button') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {EventBus} from '@/eventbus.js'
import { required, email } from 'vuelidate/lib/validators'

export default {
    name: 'user-form',
    data() {
        return {
            userId: null,
            role: null,
            name: '',
            email: '',
            username: '',
            password: '',
            tel: '',
            cell: '',
        }
    },
    validations: {
        name: { required },
        email: { email },
        role: { required }
    },
    computed: {
        ...mapGetters({
            'roles': 'users/allRoles',
        }),
        roleSelect() {
            var formatted = [];
            for (let i = 0; i < this.roles.length;i++) {
                formatted[i] = {
                    label: this.roles[i].name,
                    code: this.roles[i].id
                }
            }
            return formatted;
        }
    },
    methods: {
        ...mapActions({
            'loadUser': 'users/loadUser',
            'loadRoles': 'users/loadRoles',
            'newUser': 'users/newUser',
            'updateUser': 'users/updateUser',
        }),
        submitNew() {
            let formData = {
                name: this.name,
                email: this.email,
                role: this.role.code ? this.role.code : '',
                username: this.username,
                password: this.password,
                tel: this.tel,
                cell: this.cell,
            }
            this.newUser(formData)
                .then(res => {
                    if (this.$router.currentRoute.path == '/products/new') {
                        EventBus.$emit('closeModal', res.data.data);
                    } else {
                        this.$router.push('/users');
                    }
                    this.$toast.success(res.data.message);
                })
                .catch(err => {
                    this.$toast.error('Error');
                    console.log(err);
                });
        },
        submitUpdate() {
            const formData = {
                id: this.userId,
                name: this.name,
                email: this.email,
                role: this.role,
                username: this.username,
                tel: this.tel,
                cell: this.cell
            }
            this.updateUser(formData);
            this.$router.push('/users');
        }
    },
    created() {
        this.loadRoles();

        if (this.$route.params.id) {
            this.$store.commit('settings/setLoading', true);
            this.userId = this.$route.params.id;
            this.loadUser(this.userId).then(() => {
                let user = this.$store.getters['users/editUser'];

                this.role = user.role.name;
                this.name = user.name;
                this.email = user.email;
                this.username = user.username;
                this.tel = user.tel;
                this.cell = user.cell;
                this.$store.commit('settings/setLoading', false);
            });
        }

    }
}
</script>