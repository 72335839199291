<template>
    <div class="card mb-3">
        <div class="card-header">{{ $t('components.product.filters_title') }}</div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <label class="form-label">{{ $t('components.product.filter_by_result') }}</label>
                    <vselect multiple :items="loadResultsSelect" :options="loadResultsSelect" @input="saveResultSelect"
                        item-text="text" item-value="key" v-model="serverParams.result">
                        <template slot="selected-option" slot-scope="option">
                            <h5><span :class="'badge w-100 p-2 badge-' + option.color">{{ option.label }}</span></h5>
                        </template>
                        <template slot="option" slot-scope="option">
                            <h5><span :class="'badge w-100 p-2 badge-' + option.color">{{ option.label }}</span></h5>
                        </template>
                    </vselect>
                </div>
                <div class="col-md-6">
                    <label class="form-label">{{ $t('components.product.filter_by_status') }}</label>
                    <vselect multiple :items="loadStatusesSelect" :options="loadStatusesSelect"
                        @input="saveStatusSelect" item-text="text" item-value="key" v-model="serverParams.status">
                        <template slot="selected-option" slot-scope="option">
                            <h5><span :class="'badge w-100 p-2 badge-'+option.color">{{ option.label }}</span></h5>
                        </template>
                        <template slot="option" slot-scope="option">
                            <h5><span :class="'badge w-100 p-2 badge-'+option.color">{{ option.label }}</span></h5>
                        </template>
                    </vselect>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'widget-filters',
    methods: {
        saveResultSelect(val) {
            this.serverParams.result = val;
            this.serverParams.page = 1;
            localStorage.setItem("resultSelect", JSON.stringify(val));
            this.loadProducts();
        },
        saveStatusSelect(val) {
            this.serverParams.status = val;
            this.serverParams.page = 1;
            localStorage.setItem("statusSelect", JSON.stringify(val));
            this.loadProducts();
        },
    },
    computed: {
        ...mapGetters({
            'loadResultsSelect': 'products/loadResultsSelect',
            'loadStatusesSelect': 'products/loadStatusesSelect',
            'serverParams': 'products/getServerParams',
        }),
    }
}
</script>