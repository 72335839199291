import axios from '@/axios-auth'
//import router from '@/router';

const state = {
    token: localStorage.getItem("token") || null,
    user: JSON.parse(localStorage.getItem("user")) || null,
    role: localStorage.getItem("role") || null,
};

const getters = {
    getToken(state) {
        return state.token;
    },
    getEmail(state) {
        return state.email;
    },
    getRole(state) {
        return state.role;
    },
    getUser(state) {
        return state.user;
    },
    isAuth(state) {
        return state.token !== null;
    },
    isAdmin(state) {
        return state.role == 'admin';
    }
};

const actions = {
    // eslint-disable-next-line no-unused-vars
    async login({dispatch}, formData) {
        let response = await axios.post('/auth/login', {
            username: formData.username,
            password: formData.password
        });
        return dispatch('attempt', {
            data: response.data.data, 
            remember: formData.remember
        });
    },
    attempt({commit, dispatch}, payload) {
        try {
            commit('authUser', {
                token: payload.data.token,
                user: payload.data.user,
                role: payload.data.role,
            });
            let timeout = !payload.remember ? 3600 * 1000 : 3600 * 1000 * 24;
            dispatch('setLogout', timeout);
        } catch (e) {
            commit('clearAuth');
        }
    },
    logout({commit}) {
        commit('clearAuth');
    },
    setLogout({dispatch}, expireTime) {
        setTimeout(() => {
            dispatch('logout');
        }, expireTime);
    }
};

const mutations = {
    authUser(state, payload) {
        state.token = payload.token;
        state.user = payload.user;
        state.role = payload.role;
        localStorage.setItem("token", payload.token);
        localStorage.setItem("user", JSON.stringify(payload.user));
        localStorage.setItem("role", payload.role);
    },
    clearAuth(state) {
        state.token = null;
        state.user = null;
        state.role = null;
        localStorage.clear();
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}