<template>
    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
        <div class="card-body text-center">
            <i :class="icon"></i>
            <div class="content">
                <p class="text-muted mt-2 mb-0">{{ title }}</p>
                <p class="text-primary text-24 line-height-1 mb-2">
                    <slot></slot>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'card',
    props: {
        icon: String,
        title: String,
        color: {
            type: String,
            value: ''
        },
    }
}
</script>