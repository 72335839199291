<template>
    <div>
        <vselect 
            @search="onSearchUser" 
            :filterable="false"
            :disabled="disabled"
            @input="setSelected"
            :options="options"
            :placeholder="`${$t('components.product.customer')} (${$t('components.required')})`"
            label="name"
            :value="$store.getters['products/getUserSelected']"
            :class="classStyle">
            <template slot="no-options">
                {{ $t('components.product.search_customer') }}
            </template>
            <template slot="option" slot-scope="option">
                <span class="heading text-primary">{{ option.name }}</span>
                <p class="mb-1 text-muted" v-if="option.username"><strong>{{ $t('components.user.username_label') }} </strong>{{ option.username }}</p>
                <p class="mb-1 text-muted" v-if="option.email"><strong>{{ $t('components.user.email_label') }} </strong>{{ option.email }}</p>
                <p class="mb-1 text-muted" v-if="option.tel"><strong>{{ $t('components.user.phone_label') }} </strong>{{ option.tel }}</p>
                <p class="text-muted" v-if="option.cell"><strong>{{ $t('components.user.cell_label') }} </strong>{{ option.cell }}</p>
            </template>
            <template slot="selected-option" slot-scope="option">
                {{ option.name }}
            </template>
            <template #list-header>
                <div v-if="totalUsers > 0" class="text-center">
                    Pagina <strong>{{ page }}</strong> di <strong>{{ totalPages }}</strong><br/>
                    <strong>{{ totalUsers }}</strong> clienti totali
                </div>
            </template>
            <template v-slot:list-footer="{ search }">
                <nav aria-label="Pagination users" v-if="search.length > 3">
                    <ul class="pagination justify-content-center">
                        <li class="page-item" v-if="hasPrevPage">
                            <a class="page-link" @click="userListBack(search)"><font-awesome-icon icon="fa-solid fa-arrow-left"></font-awesome-icon></a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" v-if="prevPage > 0" @click="prevPageClick(search)">{{ prevPage }}</a>
                        </li>
                        <li class="page-item active">
                            <a class="page-link">{{ page }}</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" v-if="nextPage <= totalPages " @click="nextPageClick(search)">{{ nextPage }}</a>
                        </li>
                        <li class="page-item" v-if="hasNextPage">
                            <a class="page-link" @click="userListNext(search)"><font-awesome-icon icon="fa-solid fa-arrow-right"></font-awesome-icon></a>
                        </li>
                    </ul>
                </nav>
            </template>
        </vselect>
    </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

export default {
    model: {
        prop: 'user',
        event: 'change'
    },
    props: {
        user: Object,
        disabled: Boolean,
        classStyle: Object
    },
    data() {
        return {
            options: [],
            page: 1,
            perPage: 10,
            totalPages: 1,
            totalUsers: 0,
            prevPage: 0,
            nextPage: 2,
        }
    },
    computed: {
        hasPrevPage() {
            return this.page !== 1;
        },
        hasNextPage() {
            return (this.page < this.totalPages) && this.totalUsers > 0;
        }
    },
    methods: {
        setSelected(value) {
            this.$store.dispatch('products/updateUserSelected', value);
            this.$emit('validation');
        },
        searchUser: _.debounce((search, page, perPage, loading, vm) => {
            axios.post('/users', {
                searchTerm: search,
                page: page,
                perPage: perPage
            }).then((res) => {
                vm.options = res.data.data.users;
                var totalPages = Math.ceil(res.data.data.total / vm.perPage)
                vm.totalPages = totalPages;
                vm.totalUsers = res.data.data.total;
                loading = false;
            });
        }, 350),
        onSearchUser(search) {
            if (search.length > 3) {
                this.page = 1;
                this.prevPage = 0;
                this.nextPage = 2;
                this.searchUser(search, this.page, this.perPage, true, this);
            }
        },
        nextPageClick(search) {
            this.page++;
            this.nextPage = this.page + 1;
            this.prevPage = this.page - 1;
            this.searchUser(search, this.page, this.perPage, true, this);
            return this.page;
        },
        prevPageClick(search) {
            this.page--;
            this.nextPage = this.page + 1;
            this.prevPage = this.page - 1;
            this.searchUser(search, this.page, this.perPage, true, this);
            return this.page;
        },
        userListBack(search) {
            if (!this.hasPrevPage) return false;
            this.prevPageClick();
            this.searchUser(search, this.page, this.perPage, true, this);
        },
        userListNext(search) {
            if (!this.hasNextPage) return false;
            if (this.nextPageClick() > this.totalPages) return false;
            this.searchUser(search, this.page, this.perPage, true, this);
        },
    },
}
</script>