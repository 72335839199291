const messages = {
    header: {
        flag: {
            italian: 'Italiano',
            english: 'Inglese',
        },
        edit_profile: 'Modifica profilo',
        logout: 'Esci',
        login: 'Accedi',
    },
    sidebar: {
        dashboard: 'Dashboard',
        repairs: 'Riparazioni',
        users: 'Clienti',
    },
    pages: {
        dashboard: {
            repairs: 'Riparazioni',
            users: 'Clienti',
        },
        login: {
            signin: 'Accedi al tuo account',
            username_email: 'Username / Indirizzo email',
            password: 'Password',
            remember_me: 'Ricordami',
            login: 'Accedi',
        }
    },
    components: {
        chart: {
            update: 'Aggiorna',
            daily: 'Giornaliero',
            monthly: 'Mensile',
            annually: 'Annuale',
        },
        notification: {
            unread: 'Non letto',
            mark_all_read: 'Segna come già letto',
            delete_all: 'Elimina tutto',
            no_notifications: 'Non ci sono notifiche.'
        },
        user: {
            email_label: 'Email:',
            username_label: 'Nome utente:',
            phone_label: 'Tel.',
            cell_label: 'Cell.',
            customer_data: 'Anagrafica cliente',
            create_new_button: 'Crea nuovo utente',
            customer: 'Cliente',
            name: 'Nome',
            username: 'Nome utente',
            password: 'Password',
            email: 'Email',
            phone: 'Tel',
            cell: 'Cell',
            role: 'Ruolo',
            repairs: 'Riparazioni',
            actions: 'Azioni',
            update_button: 'Aggiorna scheda cliente',
            create_button: 'Crea nuovo cliente',
        },
        profile: {
            update_button: 'Aggiorna profilo',
        },
        product: {
            serial_label: 'Seriale / IMEI:',
            defect_reported_label: 'Difetto dichiarato:',
            defect_detected_label: 'Difetto riscontrato:',
            price_label: 'Prezzo:',
            intervention_label: 'Intervento eseguito:',
            note_label: 'Note:',
            passcode_label: 'Codice/i di sblocco',
            pattern_label: 'Segno di sblocco',
            result_label: 'Esito:',
            status_label: 'Stato:',
            empty_products_temp: 'Svuota tutto',
            confirm: 'Conferma',
            confirm_print: 'Conferma e Stampa',
            created: 'Prodotto creato.',
            updated: 'Prodotto aggiornato.',
            customer: 'Cliente',
            product: 'Prodotto',
            actions: 'Azioni',
            search_customer: 'Cerca un cliente...',
            pickup_date: 'Data di ritiro',
            model: 'Modello',
            serial: 'Seriale / IMEI',
            defect_reported: 'Difetto dichiarato',
            defect_reported_placeholder: 'Qui scrivi il difetto riscontrato dal cliente',
            defect_detected: 'Difetto riscontrato',
            defect_detected_placeholder: 'Qui scrivi il difetto riscontrato in negozio al momento del ritiro',
            intervention: 'Intervento eseguito',
            intervention_placeholder: 'Intervento eseguito in laboratorio',
            price: 'Prezzo',
            note: 'Note',
            result: 'Esito',
            status: 'Stato',
            additional_information: 'Informazioni aggiuntive:',
            pattern_not_saved: 'Il codice non è salvato nel database.',
            pattern_showing_on_print: 'Verrà stampato il pattern per farlo compilare al cliente.',
            warranty: 'Garanzia',
            passcode: 'Codice PIN',
            total_product: 'Non ci sono prodotti | 1 prodotto | <strong>{count}</strong> prodotti totali',
            update_button: 'Aggiorna prodotto',
            create_button: 'Crea prodotto',
            create_new_button: 'Crea nuova scheda',
            clear_form: 'Svuota tutto',
            withdrawn_products: 'Prodotti ritirati',
            withdrawn_on: 'Ritirato il',
            filters_title: 'Filtri',
            filter_by_result: 'Esito',
            filter_by_status: 'Stato',
            print_footer_1: 'Ricevuta valida per il ritiro dei prodotti in riparazione. Il ritiro può essere effettuato solo se in possesso della ricevuta.',
            print_footer_2: 'Scansiona questo codice con il tuo cellulare per verificare lo stato della riparazione.',
            history: 'Storico',
            quick_creation: 'Creazione rapida',
            images: 'Immagini',
            buttons: {
                copy_url: 'Copia link',
                print: 'Stampa ricevuta',
                view_info: 'Visualizza info',
                edit: 'Modifica scheda',
                delete: 'Elimina'
            }
        },
        required: 'Obbligatorio',
        loading_text: 'Attendere prego...',
        copied: 'Link copiato!',
    },
    error: {
        choose_customer: 'Scegli prima il cliente',
        pickup_date: 'Scegli una data di ritiro',
        model: 'Devi inserire almeno un modello / nome prodotto',
        result: 'Scegli esito della riparazione',
        name: 'Il nome è obbligatorio',
        email_not_valid: 'Indirizzo email non valido',
        role_required: 'Il ruolo è obbligatorio',
        password_min_length: 'La password deve essere minimo {minLength} caratteri',
        auth_error: 'Username e/o password errati.',
        auth_success: 'Accesso consentito.',
    },
    placeholder: {
        search: 'Cerca e premi INVIO',
        barcode_search: 'Codice a barre (premi il 𝘁𝗮𝘀𝘁𝗼 𝟱 per iniziare a scrivere)',
        model: 'Modello',
        serial: 'Seriale / IMEI',
        defect_reported: 'Qui scrivi il difetto riscontrato dal cliente',
        defect_detected: 'Qui scrivi il difetto riscontrato in negozio al momento del ritiro',
        intervention: 'Intervento eseguito in laboratorio',
    }
}

export default messages;