<template>
    <div>
        <div class="row">
            <div class="col-md-6" v-if="isAuth && isAdmin">
                <BarcodeSearch classStyle="mb-3" />
            </div>
            <div class="col-md-6" v-if="isAuth && isAdmin">
                <QuickCreation classStyle="mb-3" />
            </div>
        </div>
        <div class="row" v-if="isAuth && isAdmin">
            <div class="col-lg-3 col-md-6 col-sm-6" @click="$router.push('/products')">
                <Card :title="$t('pages.dashboard.repairs')" icon="i-Big-Data">
                    {{ total.data }}
                </Card>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6" @click="$router.push('/users')">
                <Card :title="$t('pages.dashboard.users')" icon="i-Business-ManWoman">
                    {{ totalUsers }}
                </Card>
            </div>
        </div>
        <vue-element-loading :active="isLoading" spinner="bar-fade-scale" />

        <div class="row" v-if="isAuth">
            <div class="col-md-6">
                <div class="card mb-4">
                    <div class="card-body">
                        <TotalChart />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card mb-4">
                    <div class="card-body">
                        <FilteredChart />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Card from './Widget/Card'
import TotalChart from './Charts/TotalChart'
import FilteredChart from './Charts/FilteredChart'
import BarcodeSearch from './Widget/BarcodeSearch.vue'
import QuickCreation from './Widget/QuickCreation.vue';

export default {
    name: 'dashboard',
    components: {
    Card,
    TotalChart,
    FilteredChart,
    BarcodeSearch,
    QuickCreation
},
    data() {
        return {
            isLoading: false,
        }
    },
    computed: {
        ...mapGetters({
            'total': 'stats/getTotalProducts',
            'totalUsers': 'stats/getTotalUsers',
            'isAuth': 'auth/isAuth',
        }),
    },
    beforeMount() {
        if (this.isAuth) {
            this.isLoading = true;
            this.loadTotalProducts()
            this.loadStatsUsers();
            this.isLoading = false;
        }
    }
}
</script>