<template>
    <div class="row mb-4">
        <vue-element-loading :active="loader" spinner="bar-fade-scale" />

        <!-- Barcode Search Edit -->
        <div class="col-md-6">
            <BarcodeSearch classStyle="mb-3" />
        </div>

        <!-- Quick Creation Widget -->
        <div class="col-md-6">
            <QuickCreation classStyle="mb-3" />
        </div>

        <div class="col-md-12">
            <!-- New Product Button -->
            <button v-if="isAdmin" class="btn btn-primary btn-block mb-3" @click="openProduct">{{ $t('components.product.create_new_button') }}</button>

            <!-- Filter Widget -->
            <Filters />

            <!-- Table Products -->
            <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
                @on-sort-change="onSortChange"
                @on-search="onSearchFilter"
                @on-column-filter="onColumnFilter"
                :select-options="{
                    enabled: true,
                    selectOnCheckboxOnly: true,
                }"
                :columns="columns"
                :rows="getProducts.data"  
                :pagination-options="{ 
                    enabled: true,
                    mode: 'pages',
                    setCurrentPage: serverParams.page,
                    perPageDropdown: [50, 100, 250],
                    dropdownAllowAll: false,
                    perPage: serverParams.perPage
                }"
                :totalRows="getProducts.totalRecords"
                :isLoading.sync="isLoading"
                :search-options="{
                    enabled: true,
                    trigger: 'enter',
                    placeholder: $t('placeholder.search')
                }"
                :sort-options="{
                    enabled: true,
                    multipleColumns: true,
                    initialSortBy: [
                        {field: 'pickup_date', type: 'desc'},
                        {field: 'id', type: 'desc'}
                    ],
                }"
                styleClass="vgt-table condensed striped"
                ref="tableProduct"
                @on-selected-rows-change="onSelectedRow">
                <div slot="table-actions">
                    <div class="row m-3">
                        <div class="col">
                            <p class="h5" v-html="$tc('components.product.total_product', getProducts.totalRecords, { count: getProducts.totalRecords })"></p>
                        </div>
                    </div>
                </div>
                <template slot="loadingContent">
                    <vue-element-loading
                        spinner="bar-fade-scale" 
                        :active="isLoading" 
                        :text="$t('components.loading_text')"
                        size="80" />
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'user.name'">
                        <router-link v-if="props.row.user" :to="`/users/${props.row.user.id}`"><strong>{{ props.row.user.name }}</strong></router-link> <span v-if="(props.row.history).length > 0" class="badge badge-danger">{{ $t('components.product.history') }}</span>
                    </span>
                    <span v-else-if="props.column.field == 'id'">
                        <a href="#" @click.prevent="$modal.show('modalProductInfo', [props.row])">#{{ props.row.id }}</a>
                    </span>
                    <span v-else-if="props.column.field == 'model'">
                        {{ props.row.model }}
                        <br/>
                        <div class="d-flex gap-3">
                            <span v-if="props.row.warranty" class="badge badge-danger"><font-awesome-icon icon="fa-solid fa-shield"></font-awesome-icon></span>
                            <span v-if="props.row.passcode" class="badge badge-danger"><font-awesome-icon icon="fa-solid fa-ellipsis"></font-awesome-icon></span>
                            <span v-if="props.row.pattern" class="badge badge-danger"><font-awesome-icon icon="fa-solid fa-table-cells"></font-awesome-icon></span>
                            <span v-if="props.row.images.length > 0" class="badge badge-primary"><font-awesome-icon icon="fa-solid fa-images"></font-awesome-icon> {{ props.row.images.length }}</span>
                        </div>
                    </span>
                    <span v-else-if="props.column.field == 'price'">
                        <span v-if="!props.row.warranty"><strong>{{ vueNumberFormat(props.row.price, {precision: 2, decimal: '.', thousand: ''}) }}</strong></span>
                    </span>
                    <span v-else-if="props.column.field == 'result'">
                        <span :class="'badge w-100 p-2 badge-'+props.row.result.color" @click="changeDisplayResultSelect(props.row.id, resultSelectShow)">{{ props.row.result.name }}</span>

                        <vselect :clearable="false" v-if="elementKeyShow == props.row.id && resultSelectShow" :items="loadResultsSelect" :options="loadResultsSelect" @input="(result) => saveProductResult(result, props.row.id)" item-text="text" item-value="key" :value="{ id: props.row.result.id, label: props.row.result.name, color: props.row.result.color }">
                            <template slot="selected-option" slot-scope="option">
                                <h5><span :class="'badge w-100 p-2 badge-'+option.color">{{ option.label }}</span></h5>
                            </template>
                            <template slot="option" slot-scope="option">
                                <h5><span :class="'badge w-100 p-2 badge-'+option.color">{{ option.label }}</span></h5>
                            </template>
                        </vselect>
                    </span>
                    <span v-else-if="props.column.field == 'status'">
                        <span :class="'badge w-100 p-2 badge-'+props.row.status.color" @click="changeDisplayStatusSelect(props.row.id, statusSelectShow)">{{ props.row.status.name }}</span>

                        <vselect :clearable="false" v-if="elementKeyShow == props.row.id && statusSelectShow" :items="loadStatusesSelect" :options="loadStatusesSelect" @input="(status) => saveProductStatus(status, props.row.id)" item-text="text" item-value="key" :value="{ id: props.row.status.id, label: props.row.status.name, color: props.row.status.color }">
                            <template slot="selected-option" slot-scope="option">
                                <h5><span :class="'badge w-100 p-2 badge-'+option.color">{{ option.label }}</span></h5>
                            </template>
                            <template slot="option" slot-scope="option">
                                <h5><span :class="'badge w-100 p-2 badge-'+option.color">{{ option.label }}</span></h5>
                            </template>
                        </vselect>
                    </span>

                    <span v-else-if="props.column.field == 'actions'">     
                        <div class="btn-group">
                            <button class="btn btn-warning" type="button" @click="printButton([props.row])"><font-awesome-icon icon="fa-solid fa-print" size="lg"></font-awesome-icon></button>
                            <button class="btn btn-sm dropdown-toggle dropdown-toggle-split border-top border-right border-bottom" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span>Azioni</span></button>
                            <div class="dropdown-menu p-0 rounded" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(75px, 34px, 0px);">
                                <a class="dropdown-item" v-clipboard:copy="urlQR(props.row.id)" v-clipboard:success="copyOnSuccess" v-clipboard:error="copyOnError"><font-awesome-icon icon="fa-solid fa-link" size="lg"></font-awesome-icon> {{ $t('components.product.buttons.copy_url') }}</a>
                                <a class="dropdown-item" @click="$modal.show('modalProductInfo', [props.row])"><font-awesome-icon icon="fa-solid fa-circle-info" size="lg"></font-awesome-icon> {{ $t('components.product.buttons.view_info') }}</a>
                                <a v-if="isAdmin" class="dropdown-item" @click="$router.push('/products/'+props.row.id)"><font-awesome-icon icon="fa-solid fa-pen-to-square" size="lg"></font-awesome-icon> {{ $t('components.product.buttons.edit') }}</a>
                                <a v-if="isAdmin" class="dropdown-item bg-danger text-white" @click="deleteProd(props.row.id)"><font-awesome-icon icon="fa-solid fa-trash" size="lg"></font-awesome-icon> {{ $t('components.product.buttons.delete') }}</a>
                            </div>
                        </div>
                    </span>
                </template>
                <div slot="selected-row-actions">
                    <div class="btn-group" role="group" aria-label="Product Selected Actions">
                        <button class="btn btn-warning d-none d-lg-block" @click="printButton"><font-awesome-icon icon="fa-solid fa-print" size="lg"></font-awesome-icon></button>
                        <button class="btn btn-primary" @click="openInfo"><font-awesome-icon icon="fa-solid fa-circle-info" size="lg"></font-awesome-icon></button>
                    </div>
                </div>
            </vue-good-table>

            <!-- Product Info Modal -->
            <modal name="modalProductInfo" :scrollable="true" height="auto" width="90%" @before-open="beforeOpen">
                <ProductInfo :products="product"></ProductInfo>
            </modal>
        </div>
    </div>
</template>

<script>
import {mapMutations, mapActions, mapGetters} from 'vuex'
import ProductInfo from './ProductInfo'
import BarcodeSearch from '../Widget/BarcodeSearch.vue'
import QuickCreation from '../Widget/QuickCreation.vue'
import axios from 'axios'
import printJS from 'print-js'
import Filters from '../Widget/Filters.vue'
import { isMobile, isTablet } from 'mobile-device-detect';
var Buffer = require('safe-buffer').Buffer;

export default {
    name: 'table-product',
    data() {
        return {
            loader: false,
            url: process.env.VUE_APP_URL_MANAGER,
            columns: [
                { label: '#', field: 'id' },
                { label: this.$t('components.product.pickup_date'), field: 'pickup_date', formatFn: this.fieldPickupDate },
                { label: this.$t('components.product.customer'), field: 'user.name', sortable: false },
                { label: this.$t('components.product.product'), field: 'model' },
                { label: this.$t('components.product.price'), field: 'price', tdClass: 'text-center' },
                { label: this.$t('components.product.result'), field: 'result', sortable: false, tdClass: 'text-center' },
                { label: this.$t('components.product.status'), field: 'status', filterOptions: {  }, sortable: false, tdClass: 'text-center' },
                { label: this.$t('components.product.actions'), field: 'actions', sortable: false }
            ],
            product: null,
            printProduct: [],
            printUser: null,
            print: false,
            progress: 0,
            resultSelectShow: false,
            statusSelectShow: false,
            elementKeyShow: null,
        }
    },
    components: {
    ProductInfo,
    BarcodeSearch,
    QuickCreation,
    Filters
},
    computed: {
        ...mapGetters({
            'loadResultsSelect': 'products/loadResultsSelect',
            'loadStatusesSelect': 'products/loadStatusesSelect',
            'getProducts': 'products/getProducts',
            'serverParams': 'products/getServerParams',
            'isLoading': 'products/isLoading',
        }),
        isLoading: {
            get: function() {
                return this.$store.getters['products/isLoading'];
            },
            set: function() {

            }
        }
    },
    methods: {
        fieldPickupDate(field) {
            return this.$moment(field).format("DD/MM/YYYY");
        },
        onProgress(progress) {
            this.progress = progress;
        },
        ...mapActions({
            'deleteProduct': 'products/deleteProduct',
            'updateProductResult': 'products/updateProductResult',
            'updateProductStatus': 'products/updateProductStatus',
        }),
        ...mapMutations({
            'updateParams': 'products/updateParams',
            'setLoading': 'settings/setLoading',
        }),
        openProduct() {
            this.$router.push('/products/new');
        },
        pickupDate(objName) {
            return objName.pickup_date.slice(0,10);
        },
        saveProductResult(result, product_id) {
            this.updateProductResult({ product_id: product_id, result_id: result.code })
                .then(() => {
                    this.changeDisplayResultSelect(product_id, true);
                    this.$toast.success(this.$t('components.product.updated'));
                })
                .catch((err) => console.log(err));
        },
        saveProductStatus(status, product_id) {
            this.updateProductStatus({ product_id: product_id, status_id: status.code })
                .then(() => {
                    this.changeDisplayStatusSelect(product_id, true);
                    this.$toast.success(this.$t('components.product.updated'));
                })
                .catch((err) => console.log(err));
        },
        changeDisplayResultSelect(element, status) {
            this.elementKeyShow = element;
            this.resultSelectShow = !status;
        },
        changeDisplayStatusSelect(element, status) {
            this.elementKeyShow = element;
            this.statusSelectShow = !status;
        },

        
        // filters table
        onPageChange(params) {
            localStorage.setItem("currentPage", params.currentPage);
            this.updateParams({page: params.currentPage});
            this.loadProducts();
        },
        onPerPageChange(params) {
            localStorage.setItem("productsPerPage", params.currentPerPage);
            this.updateParams({perPage: params.currentPerPage});
            this.loadProducts();
        },
        onSortChange(params) {
            this.updateParams({
                sort: params,
            });
            this.loadProducts();
        },
        onSearchFilter(params) {
            this.updateParams({searchTerm: params.searchTerm});
            this.loadProducts();
        },
        onColumnFilter(params) {
            this.updateParams(params);
            this.loadProducts();
        },
        onSelectedRow(params) {
            if (params.selectedRows.length > 1) {
                this.printProduct = params.selectedRows;
                this.printUser = null;
            } else if (params.selectedRows.length == 1) {
                this.printProduct = params.selectedRows;
                this.printUser = params.selectedRows[0].user;
            }
        },

        // actions buttons
        deleteProd(productId) {
            this.deleteProduct(productId).then((res) => {
                this.$toast.success(res.data.message);
                this.loadProducts();
            }).catch((e) => {
                this.$toast.error(e.message);
            });
        },
        beforeOpen(event) {
            this.product = event.params;
        },
        openInfo() {
            this.$modal.show('modalProductInfo', this.printProduct);
        },
        printButton(products = false) {
            if (products != false && products.length == 1) {
                this.printProduct = products;
                this.printUser = products[0].user;
            }

            this.setLoading(true);
            
            axios({
                url: '/products/print',
                method: 'POST',
                data: {
                    products: this.printProduct,
                },
                responseType: 'blob',
            }).then((response) => {
                const file = new Blob([response.data], {
                    type: 'application/pdf'
                });
                const url = window.URL.createObjectURL(file);
                if (!isMobile || !isTablet) {
                    printJS({
                        printable: url,
                        onLoadingEnd: () => {
                            this.setLoading(false);
                        },
                    });
                } else {
                    this.setLoading(false);
                    window.open(url);
                }
            });
        },
        encryptID(id) {
            return Buffer.from(String(id)).toString('base64');
        },
        urlQR(id) {
            return this.url+'/product/qrcode/'+this.encryptID(id);
        },
        copyOnSuccess() {
            this.$toast.success('Copied');
        },
        copyOnError() {
            this.$toast.danger('Error');
        },
        
    },
    beforeMount() {
        this.loadProducts();
        this.loadResults();
        this.loadStatuses();
        this.result = JSON.parse(localStorage.getItem("resultSelect")) || [];
        this.status = JSON.parse(localStorage.getItem("statusSelect")) || [];
    }
}
</script>

<style>
.vm--container {
    z-index: 1300 !important;
}
.dropdown-actions {
    position: inherit !important;
}
.gap-3 {
    gap: 0.3rem!important;
}
</style>