import axios from "axios";

const state = {
    totalProducts: 0,
    statsProducts: 0,
    repairedProducts: 0,
    notRepairedProducts: 0,
    totalUsers: 0,
}
const getters = {
    getTotalProducts(state) {
        return state.totalProducts;
    },
    getStatsProducts(state) {
        return state.statsProducts;
    },
    getRepairedProducts(state) {
        return state.repairedProducts;
    },
    getNotRepairedProducts(state) {
        return state.notRepairedProducts;
    },
    getTotalUsers(state) {
        return state.totalUsers;
    }
}
const actions = {
    // products
    loadTotalProducts({commit}) {
        axios.post('/stats/products')
            .then(res => {
                commit('setTotalProducts', res.data);
            }).catch(err => {
                console.log(err);
            });
    },
    loadStatsProducts({commit}, payload) {
        axios.post('/stats/products/range', {
            startDate: payload.startDate,
            endDate: payload.endDate,
            period: payload.period,
        }).then(res => {
            commit('setStatsProducts', res.data);
        }).catch(err => {
            console.log(err);
        });
    },
    loadStatsRepaired({commit}, payload) {
        axios.post('/stats/products/range/filtered', {
            result_id: 2,
            startDate: payload.startDate,
            endDate: payload.endDate,
            period: payload.period,
        })
        .then(res => {
            commit('setRepairedProducts', res.data);
        }).catch(err => {
            console.log(err);
        });
    },
    loadStatsNotRepaired({commit}, payload) {
        axios.post('/stats/products/range/filtered', {
            result_id: 3,
            startDate: payload.startDate,
            endDate: payload.endDate,
            period: payload.period
        })
        .then(res => {
            commit('setNotRepairedProducts', res.data);
        }).catch(err => {
            console.log(err);
        });
    },

    // users
    loadStatsUsers({commit}) {
        axios.post('/stats/users')
            .then(res => {
                commit('setStatsUsers', res.data.data);
            }).catch(err => {
                console.log(err);
            });
    },
}
const mutations = {
    setTotalProducts(state, products) {
        state.totalProducts = products;
    },
    setStatsProducts(state, products) {
        state.statsProducts = products;
    },
    setRepairedProducts(state, products) {
        state.repairedProducts = products;
    },
    setNotRepairedProducts(state, products) {
        state.notRepairedProducts = products;
    },
    setStatsUsers(state, users) {
        state.totalUsers = users;
    } 
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}