<template>
    <!-- Quick Creation -->
    <div class="card" :class="classStyle">
        <div class="card-header">
            <h6 class="card-title mb-0"><a class="text-default t-font-boldest collapsed" data-toggle="collapse" href="#collapse-quick-creation" aria-expanded="false"><font-awesome-icon icon="fa-solid fa-bolt" size="lg" shake class="text-warning"></font-awesome-icon> {{ $t('components.product.quick_creation') }}</a></h6>
        </div>
        <div class="collapse" id="collapse-quick-creation">
            <div class="card-body">
                <div class="row mb-3">
                    <div class="col-md-6">
                        <UserSelect v-model="userUI" :disabled="false" :class="{ 'is-invalid': $v.user.$invalid }" />
                        <input type="hidden" v-model="user" @input="$v.user.$touch()" disabled />
                        <div v-if="$v.user.$dirty && !$v.user.$required" class="invalid-feedback">
                            {{ $t('error.choose_customer') }}
                        </div>
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control" id="model" @input="$v.model.$touch()" :class="{ 'is-invalid': $v.model.$dirty && $v.model.$invalid }" v-model="model" :placeholder="$t('placeholder.model')+' ('+$t('components.required')+')'">
                        <div v-if="$v.model.$dirty && !$v.model.required" class="invalid-feedback">
                            {{ $t('error.model') }}
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-6">
                        <input type="text" class="form-control" id="serial" v-model="serial" :placeholder="$t('placeholder.serial')" />
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control" id="defect_reported" v-model="defect_reported" :placeholder="$t('placeholder.defect_reported')" />
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-md-12">
                        <button class="btn btn-success btn-block" @click.prevent="submitNew" :disabled="$v.$invalid">{{ $t('components.product.create_button') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {mapActions} from 'vuex'
import UserSelect from './UserSelect.vue'

export default {
    data() {
        return {
            user: null,
            userUI: null,
            model: '',
            serial: '',
            defect_reported: '',
        }
    },
    props: {
        classStyle: {
            type: String,
            required: false
        }
    },
    components: {
    UserSelect,
},
    validations: {
        model: { required },
        user: { required }
    },
    methods: {
        ...mapActions({
            'newProducts': 'products/newProducts',
        }),
        submitNew() {
            const formData = {
                pickup_date: this.$moment().format('YYYY-MM-DD'),
                user: this.user,
                model: this.model,
                serial: this.serial,
                defect_reported: this.defect_reported,
                defect_detected: '',
                note: '',
                intervention: '',
                passcode: '',
                pattern: false,
                warranty: false,
                price: 0,
                result: {
                    label: "In laboratorio",
                    key: 5,
                    color: "info"
                },
                status: {
                    label: "Non consegnato",
                    key: 1,
                    color: "danger"
                },
            }
            this.newProducts([formData]).then(() => {
                this.$toast.success(this.$t('components.product.created'));
            });
            this.clearForm();
        },
        clearForm() {
            this.user = null;
            this.userUI = null;
            this.$store.dispatch('products/updateUserSelected', null);
            this.model = '';
            this.serial = '';
            this.defect_reported = '';
        }
    },
    watch: {
        '$store.state.products.userSelected': function() {
            this.user = this.$store.getters['products/getUserSelected'];
        }
    }
}
</script>