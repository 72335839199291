import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'


// plugins
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import vSelect from 'vue-select'
import VModal from 'vue-js-modal/dist/index.nocss.js'
import VueNumberFormat from 'vue-number-format'
import VueGoodTablePlugin from 'vue-good-table'
import VueToast from 'vue-toast-notification'
import vueCurrencyDirective from 'vue-currency-directive'
import MoneyFormat from 'vue-money-format'
import Echo from 'laravel-echo'
import { mapActions } from 'vuex'
import FlagIcon from 'vue-flag-icon'
import i18n from './i18n'
import VueElementLoading from "vue-element-loading"
import VueClipboard from 'vue-clipboard2'
import VueMobileDetection from 'vue-mobile-detection'
import { UploadMedia, UpdateMedia } from 'vue-media-upload';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faBolt } from '@fortawesome/free-solid-svg-icons'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faShield } from '@fortawesome/free-solid-svg-icons'
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { faTableCells } from '@fortawesome/free-solid-svg-icons'
import { faImages } from '@fortawesome/free-solid-svg-icons'
import { faEllipsis } from '@fortawesome/free-solid-svg-icons'

// plugins css
import 'vue-js-modal/dist/styles.css'
import 'vue-toast-notification/dist/theme-sugar.css';
import 'vue-good-table/dist/vue-good-table.css'

// plugins initialization
Vue.use(Vuelidate);
Vue.use(VueToast);
Vue.use(VueGoodTablePlugin);
Vue.use(VueNumberFormat, {prefix: '€ ', decimal: ',', thousand: '.'});
Vue.component('vselect', vSelect);
Vue.component("VueElementLoading", VueElementLoading);
Vue.use(VModal);
Vue.directive('currency', vueCurrencyDirective);
Vue.component('money-format', MoneyFormat);
Vue.use(require('vue-moment'));
Vue.use(FlagIcon);
Vue.use(VueClipboard)
Vue.component('upload-media', UploadMedia);
Vue.component('update-media', UpdateMedia);
Vue.use(VueMobileDetection);
window.Pusher = require('pusher-js');
window.Pusher.logToConsole = true;

/* add icons to the library */
library.add({faBolt, faPrint, faCircleInfo, faLink, faPenToSquare, faTrash, faArrowLeft, faArrowRight, faShield, faClockRotateLeft, faTableCells, faImages, faEllipsis})

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

axios.defaults.baseURL = process.env.VUE_APP_URL_SERVER;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.interceptors.request.use(
  (config) => {
    let token = store.getters['auth/getToken'];
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
)

axios.interceptors.response.use(null, function(err){
  if (err.response.data.status_code == 406) {
    Vue.$toast.error(err.response.data.message);
    store.dispatch('auth/logout');
  } else if (err.response.data.status_code == 404) {
    router.push('/not-found');
  }
});
  
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  forceTLS: false,
  Authorization: `Bearer ${store.getters['auth/getToken']}`,
});

Vue.mixin({
  computed: {
    isAuth() {
      return store.getters['auth/isAuth'];
    },
    isAdmin() {
      return store.getters['auth/isAdmin'];
    },
    User() {
      return store.getters['auth/getUser'];
    }
  },
  methods: {
    filterProductsByResult(products, result) {
        return products.filter(product => {
            return !product.result.name.indexOf(result);
        });
    },
    ...mapActions({
      'pushNotification': 'notifications/pushNotification',
      'loadProducts': 'products/loadProducts',
      'loadTotalProducts': 'stats/loadTotalProducts',
      'loadStatsProducts': 'stats/loadStatsProducts',
      'loadStatsRepaired': 'stats/loadStatsRepaired',
      'loadStatsNotRepaired': 'stats/loadStatsNotRepaired',
      'loadStatsUsers': 'stats/loadStatsUsers',
      'loadResults': 'products/loadResults',
      'loadStatuses': 'products/loadStatuses',
      'loadUsers': 'users/loadUsers',
      'logout': 'auth/logout',
    }),
  },
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
