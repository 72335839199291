<template>
    <CaptureImage />
</template>
<script>
import CaptureImage from '../components/Product/CaptureImage.vue'
export default {
    name: 'product-camera',
    components: {
        CaptureImage,
    }
}
</script>