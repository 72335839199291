<template>
    <div class="auth-content">
        <div class="card o-hidden">
            <div class="row">
                <div class="col-md-12">
                    <div class="p-4">
                        <h1 class="mb-3 text-18">{{ $t('pages.login.signin') }}</h1>
                        <form @submit.prevent="onSubmit">
                            <div class="form-group">
                                <label for="username">{{ $t('pages.login.username_email') }}</label>
                                <input class="form-control form-control-rounded" v-model="username" id="username" type="text" @input="$v.username.$touch()">
                            </div>
                            <div class="form-group">
                                <label for="password">{{ $t('pages.login.password') }}</label>
                                <input class="form-control form-control-rounded" v-model="password" id="password" type="password">
                            </div>
                            <label class="checkbox checkbox-primary">
                                <input type="checkbox" v-model="remember"><span>{{ $t('pages.login.remember_me') }}</span><span class="checkmark"></span>
                            </label>
                            <button v-if="!isLoading" class="btn btn-rounded btn-primary btn-block mt-2" :disabled="$v.$invalid || isLoading">{{ $t('pages.login.login') }}</button>
                            <vue-element-loading :active="isLoading" spinner="bar-fade-scale" />
                        </form>
                    </div>
                </div>
                <!--<div class="col-md-6 text-center" style="background-size: cover;background-image: url(../../dist-assets/images/photo-long-3.jpg)">
                    <div class="pr-3 auth-right"><a class="btn btn-rounded btn-outline-primary btn-outline-email btn-block btn-icon-text" href="signup.html"><i class="i-Mail-with-At-Sign"></i> Sign up with Email</a><a class="btn btn-rounded btn-outline-google btn-block btn-icon-text"><i class="i-Google-Plus"></i> Sign up with Google</a><a class="btn btn-rounded btn-block btn-icon-text btn-outline-facebook"><i class="i-Facebook-2"></i> Sign up with Facebook</a></div>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
const { required } = require('vuelidate/lib/validators')
import { mapActions } from 'vuex'

export default {
    name: 'login',
    data() {
        return {
            isLoading: false,
            username: '',
            password: '',
            remember: false,
        }
    },
    validations: {
        username: {
            required
        },
        password: {
            required
        }
    },
    methods: {
        ...mapActions({
            'login': 'auth/login',
        }),
        onSubmit() {
            this.isLoading = true;
            const formData = {
                username: this.username,
                password: this.password,
                remember: this.remember,
            }
            this.login(formData).then(() => {
                this.$toast.success(this.$t('error.auth_success'));
                this.isLoading = false;
                this.$router.push('/');
            }).catch(() => {
                this.$toast.error(this.$t('error.auth_error'));
                this.isLoading = false;
            });
        }
    },
}
</script>

<style>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>