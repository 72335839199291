<template>
    <div>
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script>
export default {
    name: 'products-view',
    components: {
    }
}
</script>