<template>
    <div>
        <upload-media
            :server="uploadServer">
        </upload-media>
    </div>
</template>

<script>
export default {
    data() {
        return {
            uploadServer: process.env.VUE_APP_URL_SERVER + '/products/capture/' + this.$route.params.user_id,
        }
    },
}
</script>