import router from '@/router';
import axios from 'axios'
import Vue from 'vue';

const state = {
    users: {
        data: [],
        totalRecords: 0,
    },
    isLoading: false,
    roles: [],
    editingUser: null,
    serverParams: {
        columnFilters: {},
        sort: [
            {
                field: '',
                type: '',
            }
        ],
        page: 1,
        perPage: 10,
        searchTerm: '',
    },
};

const getters = {
    getUsers: state => state.users,
    isLoading: state => state.isLoading,
    allRoles: state => state.roles,
    editUser: state => state.editingUser,
    loadUsersSelect(state) {
        var formatted = [];
        for (let i = 0; i < state.users.length;i++) {
            formatted[i] = {
                label: state.users[i].name,
                code: state.users[i].id,
                data: state.users[i]
            }
        }
        return formatted;
    },
};

const actions = {
    async profile() {
        let response = await axios.post('/auth/profile');
        return response;
    },
    // eslint-disable-next-line no-unused-vars
    loadUsers({commit, state}) {
        commit('setLoading');
        return new Promise((resolve, reject) => {
            axios.post('/users', state.serverParams)
            .then(res => {
                commit('setUsers', {
                    users: res.data.data.users,
                    totalRecords: res.data.data.total,
                });
                    commit('setLoading');
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
        });
    },
    // eslint-disable-next-line no-unused-vars
    loadUser({commit, dispatch}, userId) {
        return new Promise((resolve, reject) => {
            axios.post('/users/find/'+userId)
            .then(res => {
                if (res.data.success) {
                    commit('setUserEditing', res.data.data);
                }
                resolve();
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    // eslint-disable-next-line no-unused-vars
    loadRoles({commit, dispatch}) {
        axios.post('/users/roles')
            .then(res => {
                commit('setRoles', res.data.data.roles);
            })
            .catch(err => {
                console.log(err);
            });
    },
    async newUser(_, formData) {
        return await axios.put('/users/create', {
            name: formData.name,
            email: formData.email,
            password: formData.password,
            role: formData.role,
            tel: formData.tel,
            cell: formData.cell,
        });
    },
    // eslint-disable-next-line no-unused-vars
    updateUser({commit, dispatch}, formData) {
        axios.patch('/users/update/'+formData.id, formData)
        .then(res => {
            if (res.data.success) {
                Vue.$toast.success(res.data.message);
                dispatch('loadUsers');
                router.push('/users');
            }
        })
        .catch(err => {
            console.log(err);
        });
    },
    // eslint-disable-next-line no-unused-vars
    updateProfile(_, formData) {
        axios.patch('/auth/profile/update', formData)
        .then(res => {
            if (res.data.success) {
                Vue.$toast.success(res.data.message);
            }
        })
        .catch(err => {
            console.log(err);
        });
    },
    // eslint-disable-next-line no-unused-vars
    deleteUser({commit, dispatch}, userId) {
        axios.delete('/users/delete/'+userId)
            .then(res => {
                dispatch('loadUsers');
                Vue.$toast.success(res.data.message);
            })
            .catch(err => {
                Vue.$toast.error(err.data.message);
                console.log(err);
            })
    },
};

const mutations = {
    setUsers(state, payload) {
        state.users.data = payload.users;
        state.users.totalRecords = payload.totalRecords;
    },
    updateParams(state, newProps) {
        state.serverParams = Object.assign({}, state.serverParams, newProps);
    },
    setUserEditing(state, user) {
        state.editingUser = user;
    },
    setRoles(state, roles) {
        state.roles = roles;
    },
    setLoading(state) {
        state.isLoading = !state.isLoading;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}