<template>
  <div>
  <vue-element-loading
    spinner="bar-fade-scale" 
    :active="getLoading" 
    :text="$t('components.loading_text')"
    background-color="rgba(0,0,0,0.7)"
    color="#fff"
    size="80"
    is-full-screen />
  <div class="app-admin-wrap layout-sidebar-vertical sidebar-full">
        <Sidebar />
        <div class="switch-overlay"></div>
        <div class="main-content-wrap mobile-menu-content bg-off-white m-0">
            <Header />
            <!-- ============ Body content start ============= -->
            <div class="main-content pt-4">
              <router-view></router-view>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
//import SidebarMenu from '@/components/SidebarMenu'
import Header from '@/components/Header'
import Sidebar from '@/components/Sidebar'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Sidebar,
    //SidebarMenu,
    Header
  },
  created() {
    this.$moment.locale('it');
    this.$store.watch(
      () => this.$store.getters['auth/getToken'],
      getToken => {
        if (getToken == null || getToken == "") {
          this.$store.dispatch('auth/logout');
        }
      }
    );
  },
  computed: {
    ...mapGetters({
      'getLoading': 'settings/getLoading',
    }),
  },
  mounted() {
    window.Echo.channel('products')
        .listen('.newProduct', (e) => {
          if (this.User.id != e.user.id && this.isAdmin) {
            this.pushNotification({
              'title': e.message,
              'products': e.products,
              'user': e.user,
              'time': this.$moment(),
              'read': false,
            });
          }
            this.loadProducts();
        }).listen('.updateProduct', (e) => {
            if (this.User.id != e.user.id) {
              this.pushNotification({
                'title': e.message,
                'products': e.products,
                'user': e.user,
                'time': this.$moment(),
                'read': false,
              });
            }
            this.loadProducts();
        }).listen('.deleteProduct', (e) => {
          if (this.User.id != e.user.id && this.isAdmin) {
            this.pushNotification({
              'title': e.message,
              'products': e.products,
              'user': e.user,
              'time': this.$moment(),
              'read': false,
            });
            this.loadProducts();
          }
        });
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
      $route(to, from, next) {
        if (from.path == '/products/new') {
          this.$store.dispatch('products/emptyProductTemp');
        }
      },
  },
};
</script>

<style>
  .container-fluid {
    height: 100%;
    min-height: 100%;
}
.body-film {
    min-height: 100%;
    overflow-y: scroll;
}
</style>