const state = {
    notifications: [],
    id: 0,
};

const getters = {
    getNotifications(state) {
        return state.notifications;
    }
};

const actions = {
    pushNotification({commit}, payload) {
        commit('setNotifications', payload);
    },
    resetNotifications({commit}) {
        commit('clear');
    },
    clearAll({commit}) {
        commit('clear');
    },
    markRead({commit}, notification) {
        commit('mark', notification);
    },
    markReadAll({commit}) {
        commit('markAll');
    },
    removeNotification({commit}, notification) {
        commit('remove', notification);
    }
};

const mutations = {
    setNotifications(state, payload) {
        payload.id = state.id;
        state.notifications.push(payload);
        state.id++;
    },
    clear(state) {
        state.notifications = [];
        state.id = 0;
    },
    remove(state, notification) {
        state.notifications.splice(state.notifications.indexOf(notification), 1);
    },
    mark(state, notification) {
        state.notifications.map(n => {
            if (n.id == notification.id) {
                n.read = true;
            }
        });
    },
    markAll(state) {
        state.notifications.map(n => {
            n.read = true;
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}