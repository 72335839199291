import Vue from 'vue'
import VueI18n from 'vue-i18n'
import it from './it.js'
import gb from './gb.js'
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: localStorage.getItem('lang') ?? (navigator.language).substr(0,2),
    fallbackLocale: 'gb',
    messages: {
        'it': it,
        'gb': gb,
    }
});

export default i18n;