<template>
    <div>
        <vue-element-loading :active="loader" spinner="bar-fade-scale" is-full-screen />
        <div class="card card-body ul-border__bottom">
            <div class="text-center">
                <h5 class="heading text-primary"><router-link :to="`/users/${user.id}`">{{ user.name }}</router-link></h5>
                <p class="mb-1 text-muted" v-if="user.username"><strong>{{ $t('components.user.username_label') }}</strong> {{ user.username }}</p>
                <p class="mb-1 text-muted" v-if="user.email"><strong>{{ $t('components.user.email_label') }}</strong> {{ user.email }}</p>
                <p class="mb-1 text-muted" v-if="user.tel"><strong>{{ $t('components.user.phone_label') }}</strong> {{ user.tel }}</p>
                <p class="text-muted" v-if="user.cell"><strong>{{ $t('components.user.cell_label') }}</strong> {{ user.cell }}</p>
            </div>
        </div>
        <br/><br/>
        <div class="accordion">
            <div class="card" id="accordionRightIcon" v-for="product, index in productsTemp" :key="index">
                <div :class="'card-header header-elements-inline'">
                    <h6 class="card-title mb-0">
                        <a class="text-default collapsed" data-toggle="collapse" :href="'#accordion-item-icons-'+index" aria-expanded="false">
                            {{ product.model }}
                        </a>
                    </h6>
                    <div class="list-icons ul-card__list--icon-font">
                        <a class="list-icons-item" data-action="remove" @click="deleteProductTemp(product)"><i class="i-Close-Window"></i></a>
                    </div>
                </div>
                <div class="collapse" :id="'accordion-item-icons-'+index" data-parent="#accordionRightIcon">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item"><strong>{{ $t('components.product.serial_label') }}</strong> {{ product.serial }}</li>
                        <li class="list-group-item"><strong>{{ $t('components.product.defect_reported_label') }}</strong> {{ product.defect_reported }}</li>
                        <li class="list-group-item"><strong>{{ $t('components.product.defect_detected_label') }}</strong> {{ product.defect_detected }}</li>
                        <li class="list-group-item"><strong>{{ $t('components.product.price_label') }}</strong> {{ vueNumberFormat(product.price, {precision: 2, decimal: '.', thousand: ''}) }}</li>
                        <li class="list-group-item"><strong>{{ $t('components.product.result_label') }}</strong> <span :class="'m-2 badge badge-'+product.result.color">{{ product.result.label }}</span></li>
                        <li class="list-group-item"><strong>{{ $t('components.product.status_label') }}</strong> <span :class="'m-2 badge badge-'+product.status.color">{{ product.status.label }}</span></li>
                    </ul>
                </div>
            </div>
        </div>
        <br/>
        <button type="button" v-if="productsTemp.length > 0" class="btn btn-danger text-white btn-lg btn-block" @click.prevent="emptyProductTemp">{{ $t('components.product.empty_products_temp') }}</button>
        <button type="button" v-if="productsTemp.length > 0" class="btn btn-success text-white btn-lg btn-block" @click.prevent="confirmP(false)" :disabled="loader">{{ $t('components.product.confirm') }}</button>
        <button type="button" v-if="productsTemp.length > 0" class="btn btn-success text-white btn-lg btn-block d-none d-lg-block" @click.prevent="confirmP(true)" :disabled="loader">{{ $t('components.product.confirm_print') }}</button>

    </div>
</template>

<script>
import {mapGetters,mapActions, mapMutations} from 'vuex'
import axios from 'axios'
import printJS from 'print-js'
import { isMobile, isTablet } from 'mobile-device-detect';

export default {
    name: 'new-products-list',
    data() {
        return {
            loader: false,
            htmlToPdfOptions: {
                jsPDF: {
                    unit: 'mm',
                    format: [80,300],
                    orientation: 'portrait'
                },
                html2canvas: {
                    dpi: 300,
                    scale:6,
                    letterRendering: true,
                    useCORS: true
                },
            },
            products: [],
        }
    },
    props: ['user'],
    computed: {
        ...mapGetters({
            'productsTemp': 'products/getNewProductsTemp'
        })
    },
    methods: {
        ...mapActions({
            'deleteProductTemp': 'products/deleteProductTemp',
            'emptyProductTemp': 'products/emptyProductTemp',
            'newProducts': 'products/newProducts',
        }),
        ...mapMutations({
            'setLoading': 'settings/setLoading',
        }),
        confirmP(print) {
            this.setLoading(true);
            this.newProducts().then((res) => {
                this.products = res.data.data;
                this.$toast.success(this.$t('components.product.created'));

                if (print) {
                    axios({
                        url: '/products/print',
                        method: 'POST',
                        data: {
                            products: this.products,
                        },
                        responseType: 'blob',
                    }).then((response) => {
                        const file = new Blob([response.data], {
                            type: 'application/pdf'
                        });
                        const url = window.URL.createObjectURL(file);
                        if (!isMobile || !isTablet) {
                            printJS({
                                printable: url,
                                onLoadingEnd: () => {
                                    this.loader = false;
                                    this.setLoading(false);
                                },
                            });
                        } else {
                            this.setLoading(false);
                            window.open(url);
                        }
                    });
                } else {
                    this.setLoading(false);
                }

                setTimeout(() => {
                    this.products = [];
                    this.$store.dispatch('products/updateUserSelected', null);
                    this.$router.push('/products');
                }, 1000);

            });
        },
    }
}
</script>