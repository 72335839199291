<template>
    <section class="pdf-item">
        <div v-for="product in products" :key="product.id" class="card text-center mb-5">
            <div class="card-header">
                <div class="row">
                    <div class="col-md-8">
                        <h3>[#{{ product.id }}] {{ product.model }} <span v-if="product.warranty" class="badge badge-danger"><font-awesome-icon icon="fa-solid fa-shield" beat></font-awesome-icon> {{ $t('components.product.warranty') }}</span></h3>
                    </div>
                    <div class="col-md-4" v-if="isAdmin">
                        <span class="text-muted">Ultima modifica fatta da <strong>{{ product.edit_from_user.name }}</strong> il <strong>{{ product.updated_at | moment("DD/MM/YYYY") }}</strong></span>
                    </div>
                </div>
            </div>
            <div class="card-body" v-if="product.images.length > 0">
                <div class="card-title">{{ $t('components.product.images') }}</div>
                <img class="image" v-for="(image, i) in product.images" :src="image.url" :key="i" @click="index = i" />
                <vue-gallery-slideshow
                    :images="product.images" :index="index" @close="index = null"></vue-gallery-slideshow>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex justify-content-between align-items-center" v-if="product.user"><strong>{{ $t('components.product.customer') }}</strong> <router-link :to="`/users/${product.user.id}`">{{ product.user.name }}</router-link></li>
                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.withdrawn_on') }}</strong> {{ product.pickup_date | moment("DD/MM/YYYY") }}</li>
                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.serial_label') }}</strong> {{ product.serial }}</li>
                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.defect_reported_label') }}</strong> {{ product.defect_reported }}</li>
                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.defect_detected_label') }}</strong> {{ product.defect_defected }}</li>
                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.intervention_label') }}</strong> {{ product.intervention }}</li>
                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.note_label') }}</strong> {{ product.note }}</li>
                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.passcode_label') }}</strong> {{ product.passcode }}</li>
                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.price_label') }}</strong> {{ vueNumberFormat(product.price, {precision: 2, decimal: '.', thousand: ''}) }}</li>
                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.result_label') }}</strong> <span class="badge p-2" :class="'badge-'+product.result.color">{{ product.result.name ? product.result.name : product.result.label }}</span></li>
                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.status_label') }}</strong> <span class="badge p-2" :class="'badge-'+product.status.color">{{ product.status.name ? product.status.name : product.status.label }}</span></li>
            </ul>
            <div class="card-body" v-if="product.history.length > 0 && isAdmin">
                <div class="card-title">{{ $t('components.product.history') }}</div>
                <div class="accordion">
                    <div class="card" id="accordionRightIcon" v-for="(historyProduct, index) in product.history" :key="index">
                        <div :class="'card-header header-elements-inline bg-'+historyProduct.result.color">
                            <h6 class="card-title mb-0">
                                <a class="text-white collapsed" data-toggle="collapse" :href="'#accordion-item-icons-'+index" aria-expanded="false">
                                    [#{{historyProduct.id}}] {{ historyProduct.model }}  <span v-if="historyProduct.warranty" class="badge badge-danger"><font-awesome-icon icon="fa-solid fa-shield" beat></font-awesome-icon> {{ $t('components.product.warranty') }}</span>
                                </a>
                            </h6>
                        </div>
                        <div class="collapse" :id="'accordion-item-icons-'+index" data-parent="#accordionRightIcon">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.customer') }}</strong> <router-link :to="`/users/${historyProduct.user.id}`">{{ historyProduct.user.name }}</router-link></li>
                                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.withdrawn_on') }}</strong> {{ historyProduct.pickup_date | moment("DD/MM/YYYY") }}</li>
                                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.serial_label') }}</strong> {{ historyProduct.serial }}</li>
                                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.defect_reported_label') }}</strong> {{ historyProduct.defect_reported }}</li>
                                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.defect_detected_label') }}</strong> {{ historyProduct.defect_detected }}</li>
                                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.intervention_label') }}</strong> {{ historyProduct.intervention }}</li>
                                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.note_label') }}</strong> {{ historyProduct.note }}</li>
                                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.passcode_label') }}</strong> {{ historyProduct.passcode }}</li>
                                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.price_label') }}</strong> {{ vueNumberFormat(historyProduct.price, {precision: 2, decimal: '.', thousand: ''}) }}</li>
                                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.result_label') }}</strong> <span :class="'badge p-2 badge-'+historyProduct.result.color">{{ historyProduct.result.name ? historyProduct.result.name : historyProduct.result.label }}</span></li>
                                <li class="list-group-item d-flex justify-content-between align-items-center"><strong>{{ $t('components.product.status_label') }}</strong> <span :class="'badge p-2 badge-'+historyProduct.status.color">{{ historyProduct.status.name ? historyProduct.status.name : historyProduct.status.label }}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';
export default {
    name: 'product-info',
    data() {
        return {
            productsInfo: [],
            index: null,
        }
    },
    props: {
        products: Array,
        user: Array
    },
    components: {
        VueGallerySlideshow
    }
}
</script>
<style>
.image {
  width: 100px;
  height: 100px;
  background-size: cover;
  cursor: pointer;
  margin: 5px;
  border-radius: 3px;
  border: 1px solid lightgray;
  object-fit: contain;
}
</style>