const state = {
    loading: false,
    scanner: false,
}
const getters = {
    getLoading(state) {
        return state.loading;
    },
    getScanner(state) {
        return state.scanner;
    }
}
const actions = {}
const mutations = {
    setLoading(state, loading) {
        state.loading = loading;
    },
    setScanner(state, scanner) {
        state.scanner = scanner;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}