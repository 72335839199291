import Vue from 'vue'
import Vuex from 'vuex'
import products from './modules/products.module'
import auth from './modules/auth.module'
import users from './modules/users.module'
import notifications from './modules/notifications.module'
import stats from './modules/stats.module'
import settings from './modules/settings.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    products,
    users,
    notifications,
    stats,
    settings
  }
})
