<template>
    <div>
        <ProductInfo :products="product"></ProductInfo>
    </div>
</template>

<script>
import ProductInfo from '@/components/Product/ProductInfo'
import { mapActions } from 'vuex'

export default {
    name: 'public-product-view',
    data() {
        return {
            product: [],
        }
    },
    components: {
        ProductInfo,
    },
    methods: {
        ...mapActions({
            'loadPublicProduct': 'products/loadPublicProduct',
        })
    },
    created() {
        if (this.$route.params.qrcode) {
            try {
                window.atob(this.$route.params.qrcode);
            } catch {
                this.$router.push('/not-found');
            }

            let id = parseInt(atob(this.$route.params.qrcode));
            if (!Number.isInteger(id)) {
                this.$router.push('/not-found');
            }

            this.buttonNewUser = false;
            this.productId = id;
            this.loadPublicProduct(this.productId).then((res) => {
                console.log(res.data.data);
                this.product = [res.data.data];
            });
        }
    }
}
</script>
