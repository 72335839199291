<template>
    <div class="row mb-4">
        <div class="col-md-12">
            <button class="btn btn-primary btn-block m-1 mb-3" @click="openUser">{{ $t('components.user.create_new_button') }}</button>
            <vue-good-table
                mode="remote"
                @on-page-change="onPageChange"
                @on-per-page-change="onPerPageChange"
                @on-sort-change="onSortChange"
                @on-search="onSearchFilter"
                :columns="columns" 
                :rows="users.data"
                :totalRows="users.totalRecords"
                :isLoading.sync="isLoading"
                :pagination-options="{ enabled: true, jumpFirstOrLast: true }"
                :search-options="{
                    enabled: true,
                    trigger: 'enter',
                    placeholder: $t('placeholder.search')
                }"
                styleClass="vgt-table condensed"
            >
                <template slot="loadingContent">
                    <!--<span class="spinner-glow spinner-glow-danger mr-5"></span>-->
                    <vue-element-loading
                        spinner="bar-fade-scale" 
                        :active="isLoading" 
                        :text="$t('components.loading_text')"
                        size="80" />
                </template>
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'products'">
                        {{ props.row.products.length }} (<span class="text-success">{{ props.row.productsRepaired.length }}</span>/<span class="text-danger">{{ props.row.productsNotRepairable.length }}</span>)
                    </span>
                    <span v-else-if="props.column.field == 'actions'">
                        <div class="btn-group" role="group" aria-label="Product Actions">
                            <button class="btn btn-lg ripple btn-warning" @click="$router.push('/users/'+props.row.id)"><i class="i-Pen-2 text-20 t-font-boldest"></i></button>
                            <button class="btn btn-lg ripple btn-danger" @click="deleteUser(props.row.id)"><i class="i-File-Trash text-20 t-font-boldest"></i></button>
                        </div>
                    </span>
                </template>
            </vue-good-table>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
export default {
    name: 'table-user',
    data() {
        return {
            columns: [
                { label: '#', field: 'id' },
                { label: this.$t('components.user.customer'), field: 'name' },
                { label: this.$t('components.user.username'), field: 'username' },
                { label: this.$t('components.user.email'), field: 'email' },
                { label: this.$t('components.user.phone'), field: 'tel' },
                { label: this.$t('components.user.cell'), field: 'cell' },
                { label: this.$t('components.user.role'), field: 'role.name', sortable: false },
                { label: this.$t('components.user.repairs'), field: 'products', tdClass: 'text-center', sortable: false },
                { label: 'Totale riparati', field: this.totalRepaired, tdClass: 'text-center', sortable: false },
                { label: this.$t('components.user.actions'), field: 'actions', sortable: false }
            ],
            
        }
    },
    computed: {
        ...mapGetters({
            'users': 'users/getUsers',
            'isLoading': 'users/isLoading',
        }),
        isLoading: {
            get: function() {
                return this.$store.getters['users/isLoading'];
            },
            set: function() {
                
            }
        },
    },
    methods: {
        openUser() {
            this.$router.push('/users/new');
        },
        ...mapActions({
            'deleteUser': 'users/deleteUser',
        }),
        ...mapMutations({
            'updateParams': 'users/updateParams',
        }),
        onPageChange(params) {
            this.updateParams({page: params.currentPage});
            this.loadUsers();
        },
        onPerPageChange(params) {
            this.updateParams({perPage: params.currentPerPage});
            this.loadUsers();
        },
        onSortChange(params) {
            this.updateParams({
                sort: [{
                    type: params[0].type,
                    field: params[0].field,
                }],
            });
            this.loadUsers();
        },
        onSearchFilter(params) {
            this.updateParams({searchTerm: params.searchTerm});
            this.loadUsers();
        },
        totalRepaired(row) {
            let sum = 0;
            for (const [, value] of Object.entries(row.productsRepaired)) {
                sum += parseFloat(value.price);
            }
            return this.vueNumberFormat(sum, {precision: 2, decimal: '.', thousand: ''});
        },
    },
    beforeMount() {
        this.loadUsers();
    }
}
</script>