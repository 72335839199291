<template>
    <header class="main-header bg-white d-flex justify-content-between p-2">
        <div class="header-toggle">
            <div class="menu-toggle mobile-menu-icon">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <h2>{{ this.$route.meta.title }}</h2>
        </div>
        <div class="header-part-right">
            <!-- Notificaiton -->
            <Notification v-if="isAuth" />
            <!-- Notification End -->
            <!-- User avatar dropdown -->
            <div class="dropdown">
                <div v-if="isAuth" class="user mr-3">
                    <img src="/images/faces/1.jpg" id="userDropdown" alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                        <div class="dropdown-header">
                            <i class="i-Lock-User mr-1"></i> {{ User.name }}
                        </div>
                        <router-link class="dropdown-item" to="/profile">{{ $t('header.edit_profile') }}</router-link>
                        <a class="dropdown-item" href="#" @click="logout">{{ $t('header.logout') }}</a>
                    </div>
                </div>
                <div v-else class="user col align-self-end">
                    <img src="/images/faces/1.jpg" id="userDropdown" alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                        <router-link class="dropdown-item" to="/login">{{ $t('header.login') }}</router-link>
                    </div>
                </div>
            </div>
            <div class="dropdown text-white">
                <button class="btn btn-primary dropdown-toggle" id="dropdownMenuButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <flag :iso="selectedLang.flag"></flag> {{ $t('header.flag.'+selectedLang.name) }}
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" v-for="(lang, i) in langsChoose" :key="`Lang-${i}`" @click="changeLanguage(lang.key)">
                        <flag :iso="lang.flag"></flag> {{ $t('header.flag.'+lang.name) }}
                    </a>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapActions } from 'vuex'
import Notification from '@/components/Notification/Notifications.vue'
export default {
    name: 'tempHeader',
    data() {
        return {
            langSelected: 'it',
            langs: [
                { name: 'italian', key: 'it', flag: 'it' },
                { name: 'english', key: 'gb', flag: 'gb' },
            ]
        }
    },
    components: {
        Notification
    },
    computed: {
        selectedLang() {
            return this.langs.find(e => e.key == this.$i18n.locale);
        },
        langsChoose() {
            return this.langs.filter(e => e.key != this.selectedLang.key);
        }
    },
    methods: {
        ...mapActions({
            'authLogout': 'auth/logout'
        }),
        logout() {
            this.authLogout();
            this.$router.push('/login');
        },
        changeLanguage(lang) {
            this.$i18n.locale = lang;
            localStorage.setItem('lang', lang);
        }
    }
}
</script>