<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col-md-6">
                        <date-picker name="startDate" v-model="dates.startDate" value-type="YYYY-MM-DD" format="DD/MM/YYYY" :disabled-date="disabledAfterToday"></date-picker>
                    </div>
                    <div class="col-md-6">
                        <date-picker name="endDate" v-model="dates.endDate" value-type="YYYY-MM-DD" format="DD/MM/YYYY" :disabled-date="disabledAfterToday"></date-picker>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-6">
                        <select name="period" v-model="dates.period" class="form-control">
                            <option value="daily">{{ $t('components.chart.daily') }}</option>
                            <option value="monthly" selected>{{ $t('components.chart.monthly') }}</option>
                            <option value="annually">{{ $t('components.chart.annually') }}</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-primary w-100" @click="updateChart" :disabled="(this.dates.startDate != null && this.dates.endDate != null) ? false : true">{{ $t('components.chart.update') }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
                <Chart :chartData="{
                    labels: total.date,
                    datasets: [{
                        label: 'Riparazioni',
                        data: total.numbers,
                        borderColor: 'black',
                        tension: 0.1,
                        fill: false
                    }]
                }" :options="{
                    responsive: true,
                    maintainAspectRatio: false
                }"></Chart>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from '@/components/Widget/Chart'
import DatePicker from 'vue2-datepicker';
import { mapGetters } from 'vuex'

export default {
    name: 'total-chart',
    components: {
        Chart,
        DatePicker
    },
    data() {
        return {
            dates: {
                period: 'monthly',
                startDate: null,
                endDate: null,
            }
        }
    },
    computed: {
        ...mapGetters({
            'total': 'stats/getStatsProducts',
        })
    },
    methods: {
        disabledAfterToday(date){
            const today = new Date();

            return date > today;
        },
        updateChart() {
            this.loadStatsProducts(this.dates);
        },
    },
    beforeMount() {
        let today = this.$moment().format('YYYY-MM-DD');
        let lastYear = this.$moment().subtract(1, 'year').format('YYYY-MM-DD');
        this.dates.endDate = today;
        this.dates.startDate = lastYear;
        this.loadStatsProducts(this.dates);
    }
}
</script>