const messages = {
    header: {
        flag: {
            italian: 'Italian',
            english: 'English',
        },
        edit_profile: 'Edit profile',
        logout: 'Log out',
        login: 'Log in'
    },
    sidebar: {
        dashboard: 'Dashboard',
        repairs: 'Repairs',
        users: 'Customers',
    },
    pages: {
        dashboard: {
            repairs: 'Repairs',
            users: 'Customers',
        },
        login: {
            signin: 'Sign in to your account',
            username_email: 'Username / Email address',
            password: 'Password',
            remember_me: 'Remember me',
            login: 'Log in',
        }
    },
    components: {
        chart: {
            update: 'Update',
            daily: 'Daily',
            monthly: 'Monthly',
            annually: 'Annualy',
        },
        notification: {
            unread: 'Unread',
            mark_all_read: 'Mark as read',
            delete_all: 'Delete all',
            no_notifications: 'No notifications.'
        },
        user: {
            email_label: 'Email address:',
            username_label: 'Username:',
            phone_label: 'Phone:',
            cell_label: 'Cell.',
            customer_data: 'Customer data',
            create_new_button: 'Create new customer',
            customer: 'Customer',
            name: 'Name',
            username: 'Username',
            password: 'Password',
            email: 'Email address',
            phone: 'Phone',
            cell: 'Cell',
            role: 'Role',
            repairs: 'Repairs',
            actions: 'Actions',
            update_button: 'Update customer',
            create_button: 'Create new customer',
        },
        profile: {
            update_button: 'Update profile',
        },
        product: {
            serial_label: 'Serial / IMEI:',
            defect_reported_label: 'Reported defective:',
            defect_detected_label: 'Detected defective:',
            price_label: 'Price:',
            intervention_label: 'Intervention performed:',
            note_label: 'Note:',
            passcode_label: 'Unlock code/s',
            pattern_label: 'Pattern unlock',
            result_label: 'Result:',
            status_label: 'Status:',
            empty_products_temp: 'Empty all',
            confirm: 'Confirm',
            confirm_print: 'Confirm & Print',
            created: 'Product created.',
            updated: 'Product updated.',
            customer: 'Customer',
            product: 'Product',
            actions: 'Actions',
            search_customer: 'Search customer...',
            pickup_date: 'Pickup Date',
            model: 'Model',
            serial: 'Serial / IMEI',
            defect_reported: 'Reported defective',
            defect_detected: 'Defected defective',
            defect_reported_placeholder: 'Write reported defective from customer.',
            defect_detected_placeholder: 'Write here defected defective from assistance.',
            intervention_placeholder: 'Intervention performed in assistance',
            intervention: 'Intervention performed',
            price: 'Price',
            note: 'Note',
            result: 'Result',
            status: 'Status',
            additional_information: 'Additional information:',
            pattern_not_saved: 'Code not saved on database.',
            pattern_showing_on_print: 'The pattern will be printed for the customer to fill in.',
            warranty: 'Warranty',
            passcode: 'PIN code',
            total_product: 'No products | 1 product | <strong>{count}</strong> total products',
            update_button: 'Update product',
            create_button: 'Create product',
            create_new_button: 'Create new product',
            search_placeholder: 'Search and press ENTER',
            clear_form: 'Empty form',
            withdrawn_products: 'Withdrawn products',
            withdrawn_on: 'Withdrawn on',
            filters_title: 'Filters',
            filter_by_result: 'Result',
            filter_by_status: 'Status',
            print_footer_1: 'Receipt valid to withdraw your repairs. The withdrawal can be made only if in possession of the receipt.',
            print_footer_2: 'Scan this code with your mobile phone to verify the repair status.',
            history: 'History',
            quick_creation: 'Quick creation',
            images: 'Images',
            buttons: {
                copy_url: 'Copy link',
                print: 'Print receipt',
                view_info: 'View info',
                edit: 'Edit product',
                delete: 'Delete'
            }
        },
        required: 'Required',
        loading_text: 'Please wait...',
        copied: 'Link copied!',
    },
    error: {
        choose_customer: 'Choose the customer first',
        pickup_date: 'Choose pickup date',
        model: 'You must enter at least one model / product name',
        result: 'Choose repair outcome',
        name: 'Name is required',
        email_not_valid: 'Email address isn\'t valid',
        role_required: 'Role is required',
        password_min_length: 'Password must be minimum {minLength} characters',
        auth_error: 'Bad username and/or password.',
        auth_success: 'Login failed.',
    },
    placeholder: {
        search: 'Search and press ENTER',
        barcode_search: 'Bar code (press number 5 for write)',
        model: 'Model',
        serial: 'Serial / IMEI',
        defect_reported: 'Write reported defective from customer.',
        defect_detected: 'Write here defected defective from assistance.',
        intervention: 'Intervention performed in assistance',
    }
}

export default messages;