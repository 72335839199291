<template>
    <div>
        <StreamBarcodeReader v-if="$store.getters['settings/getScanner']" @decode="onDecode"></StreamBarcodeReader>

        <div class="row">

            <div v-if="!product" :class="user ? 'col-md-4' : 'd-none'">
                <div class="card mb-3">
                    <div class="card-body">
                        <NewProductsList v-if="user" :user="user"></NewProductsList>
                    </div>
                </div>
            </div>

            <div :class="product || !user ? 'col-md-12' : 'col-md-8'">
                <div class="card mb-3">
                    <div class="card-body">
                        <div class="row">
                            <div :class="buttonNewUser ? 'col-md-10' : 'col-md-12'">
                                <UserSelect v-model="userUI" :disabled="products.length > 0" />
                                <input type="hidden" v-model="user" @input="$v.user.$touch()" disabled />
                                <div v-if="$v.user.$dirty && !$v.user.$required" class="invalid-feedback">
                                    {{ $t('error.choose_customer') }}
                                </div>
                            </div>
                            <div v-if="buttonNewUser" class="col-md-2">
                                <a class="btn btn-success w-100" @click.prevent="$modal.show('modalUser')"><i class="i-Add-User text-20 text-white t-font-boldest"></i></a>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-4">
                                <label for="pickupDate" class="form-label">{{ $t('components.product.pickup_date') }} <span class="badge badge-danger">{{ $t('components.required') }}</span></label>
                                <date-picker id="pickupDate" class="form-control" :input-class="{}" :class="{ 'is-invalid': $v.pickup_date.$dirty && $v.pickup_date.$invalid }"  @input="$v.pickup_date.$touch()" v-model="pickup_date" value-type="YYYY-MM-DD" format="DD/MM/YYYY" :disabled-date="disabledAfterToday"></date-picker>
                                <div v-if="$v.pickup_date.$dirty && !$v.pickup_date.required" class="invalid-feedback">
                                    {{ $t('error.pickup_date') }}
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label for="model" class="form-label">{{ $t('components.product.model') }} <span class="badge badge-danger">{{ $t('components.required') }}</span></label>
                                <input type="text" class="form-control" :class="{ 'is-invalid': $v.model.$dirty && $v.model.$invalid }" id="model" @input="$v.model.$touch()" v-model="model">
                                <div v-if="$v.model.$dirty && !$v.model.required" class="invalid-feedback">
                                    {{ $t('error.model') }}
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label for="serial" class="form-label">{{ $t('components.product.serial') }}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" id="serial" v-model="serial">
                                    <div class="input-group-append">
                                        <button class="btn btn-primary" @click="$store.commit('settings/setScanner', true)"><i class="i-Bar-Code"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                            <div class="custom-separator"></div>
                                <h6 class="card-title mb-0"><a class="text-default" data-toggle="collapse" href="#product-images" aria-expanded="true">{{ $t('components.product.images') }}</a></h6>
                                <div class="collapse" :class="{ 'show': savedImages.length > 0 }" id="product-images" style="">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6 text-center" v-if="isDesktop">
                                                <span class="text-primary">Scansiona il QR Code in basso per importare foto da altro dispositivo!</span>
                                                <qrcode-vue :value="scanImageURL" height="25"></qrcode-vue>
                                            </div>
                                            <div :class="isDesktop ? 'col-md-6' : 'col-md-12'">
                                                <upload-media
                                                    :server="uploadServer"
                                                    @media="uploadImages"
                                                    @media_remote="uploadImagesRemote"
                                                    v-if="!product">
                                                </upload-media>
                                                <update-media
                                                    v-if="product"
                                                    :server='uploadServer'
                                                    :media_server="getImagesServer + '/' + productId"
                                                    @added-media="uploadImages"
                                                    @added-media-remote="uploadImagesRemote"
                                                    @saved-media="savedMedia"
                                                    @deleted-media="deletedMedia">
                                                </update-media>
                                                <span class="text-danger">Max 2MB per file</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div class="custom-separator"></div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label for="defect_reported" class="form-label">{{ $t('components.product.defect_reported') }}</label>
                                <input type="text" class="form-control" id="defect_reported" v-model="defect_reported" :placeholder="$t('placeholder.defect_reported')">
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">
                                <label for="defect_detected" class="form-label">{{ $t('components.product.defect_detected') }}</label>
                                <input type="text" class="form-control" id="defect_detected" v-model="defect_detected" :placeholder="$t('placeholder.defect_detected')">
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <label for="intervention" class="form-label">{{ $t('components.product.intervention') }}</label>
                                <input type="text" class="form-control" id="intervention" v-model="intervention" :placeholder="$t('placeholder.intervention')">
                            </div>
                            <div class="col-md-6">
                                <label class="form-label">{{ $t('components.product.price') }}</label>
                                <div class="input-group mb-3">
                                    <span class="input-group-text" id="price-input">&euro;</span>
                                    <input type="text" class="form-control" v-model="price" :aria-label="$t('components.product.price')" aria-describedby="price-input" @blur="adjustPrice(price)">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card-body">
                                    <div class="custom-separator"></div>
                                    <div class="card-title">{{ $t('components.product.additional_information') }}</div>
                                    <label class="checkbox checkbox-primary">
                                        <input type="checkbox" v-model="passcodeCheck" name="passcodeCheck">
                                        <span>PIN</span
                                        ><span class="checkmark"></span>
                                    </label>
                                    <div v-if="passcodeCheck" class="mb-2">
                                        <label for="passcode" class="form-label">{{ $t('components.product.passcode_label') }}</label>
                                        <input type="text" v-model="passcode" class="form-control" id="passcode" :placeholder="$t('components.product.passcode_label')" />
                                    </div>
                                    <label class="checkbox checkbox-primary">
                                        <input type="checkbox" v-model="pattern" name="pattern">
                                        <span>{{ $t('components.product.pattern_label') }}</span>
                                        <span class="checkmark"></span><br/>
                                        <span class="text-success" v-if="pattern">{{ $t('components.product.pattern_not_saved') }}<br/>{{ $t('components.product.pattern_showing_on_print') }}</span>
                                    </label>
                                    <label class="checkbox checkbox-primary">
                                        <input type="checkbox" v-model="warranty" name="warranty">
                                        <span>{{ $t('components.product.warranty') }}</span>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="note" class="form-label">{{ $t('components.product.note') }}</label>
                                <textarea class="form-control" id="note" v-model="note"></textarea>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <label for="result" class="form-label">{{ $t('components.product.result') }} <span class="badge badge-danger">{{ $t('components.required') }}</span></label>
                                <vselect :items="loadResultsSelect" :options="loadResultsSelect" :class="{ 'is-invalid': $v.result.$invalid }" @input="$v.result.$touch()" :required="!result" item-text="text" item-value="key" v-model="result">
                                        <template slot="selected-option" slot-scope="option">
                                            <h5><span :class="'badge w-100 p-2 badge-'+option.color">{{ option.label }}</span></h5>
                                        </template>
                                        <template slot="option" slot-scope="option">
                                            <h5><span :class="'badge w-100 p-2 badge-'+option.color">{{ option.label }}</span></h5>
                                        </template>
                                </vselect>
                                <div v-if="$v.result.$dirty && !$v.result.$required" class="invalid-feedback">
                                    {{ $t('error.result') }}
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="status" class="form-label">{{ $t('components.product.status') }} <span class="badge badge-danger">{{ $t('components.required') }}</span></label>
                                <vselect :items="loadStatusesSelect" :options="loadStatusesSelect" :class="{ 'is-invalid': $v.result.$invalid }" @input="$v.status.$touch()" :required="!status" item-text="text" item-value="key" v-model="status">
                                    <template slot="selected-option" slot-scope="option">
                                        <h5><span :class="'badge p-2 w-100 badge-'+option.color">{{ option.label }}</span></h5>
                                    </template>
                                    <template slot="option" slot-scope="option">
                                        <h5><span :class="'badge p-2 w-100 badge-'+option.color">{{ option.label }}</span></h5>
                                    </template>
                                </vselect>
                                <div v-if="$v.status.$dirty && !$v.status.$required" class="invalid-feedback">
                                    {{ $t('error.status') }}
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div :class="product ? 'col-md-12' : 'col-md-6'">
                                <button v-if="product" class="btn btn-primary btn-block" @click.prevent="submitUpdate" :disabled="$v.$invalid">
                                    <span v-if="!isLoading">{{ $t('components.product.update_button') }}</span>
                                    <span v-else><div class="loader-bubble loader-bubble-danger m-1"></div></span>
                                </button>
                                <button v-else class="btn btn-primary btn-block" @click.prevent="submitNew" :disabled="$v.$invalid">{{ $t('components.product.create_button') }}</button>
                            </div>
                            <div class="col-md-6" v-if="!product">
                                <button class="btn btn-warning btn-block" @click.prevent="clearForm">{{ $t('components.product.clear_form') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <modal name="modalUser" :scrollable="true" height="auto">
                <div class="p-4">
                    <UserForm></UserForm>
                </div>
            </modal>
        </div>
    </div>
</template>


<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import NewProductsList from './NewProductsList'
import UserForm from '@/components/User/UserForm'
import {EventBus} from '@/eventbus.js'
import { required } from 'vuelidate/lib/validators'
import DatePicker from 'vue2-datepicker';
import axios from 'axios'
import _ from 'lodash'
import { StreamBarcodeReader } from 'vue-barcode-reader'
import QrcodeVue from 'qrcode.vue';
import { isWindows, isMacOs } from 'mobile-device-detect';

import 'vue-select/dist/vue-select.css'
import 'vue2-datepicker/index.css';
import UserSelect from '../Widget/UserSelect.vue'

export default {
    name: 'product-form',
    data() {
        return {
            isScanner: false,
            uploadServer: process.env.VUE_APP_URL_SERVER + '/products/upload',
            getImagesServer: process.env.VUE_APP_URL_SERVER + '/products/get_images',
            productId: null,
            product: null,
            pickup_date: '',
            user: null,
            userUI: null,
            options: [],
            images: [],
            remoteImages: [],
            savedImages: [],
            deletedImages: [],
            model: '',
            serial: '',
            defect_reported: '',
            defect_detected: '',
            note: '',
            passcodeCheck: false,
            passcode: '',
            pattern: false,
            warranty: false,
            intervention: '',
            price: '',
            result: {},
            status: {},
            buttonNewUser: true,
        }
    },
    validations: {
        model: { required },
        user: { required },
        result: { required },
        status: { required },
        pickup_date: { required }
    },
    components: {
        NewProductsList,
        UserForm,
        DatePicker,
        StreamBarcodeReader,
        UserSelect,
        'qrcode-vue': QrcodeVue
    },
    computed: {
        isDesktop() {
            return isWindows || isMacOs;
        },
        ...mapGetters({
            'products': 'products/getNewProductsTemp',
            'loadUsersSelect': 'users/loadUsersSelect',
            'loadResultsSelect': 'products/loadResultsSelect',
            'loadStatusesSelect': 'products/loadStatusesSelect',
            'isLoading': 'products/isLoading',
        }),
        isLoading: {
            get: function() {
                return this.$store.getters['products/isLoading'];
            },
            set: function() {

            }
        },
        scanImageURL() {
            let url = this.$router.resolve({ 
                name: 'capture_image',
                params: { 
                    user_id: this.User.id, 
                    product_id: this.product ? this.product.id : null 
                },
            });

            return process.env.VUE_APP_URL_MANAGER + url.href;
        }
    },
    methods: {
        ...mapMutations({
            'setLoading': 'settings/setLoading',
        }),
        ...mapActions({
            'loadProduct': 'products/loadProduct',
            'newProduct': 'products/newProduct',
            'updateProduct': 'products/updateProduct',
            'pushNewProductTemp': 'products/pushNewProductTemp',
            'emptyProductTemp': 'products/emptyProductTemp',
            'loadUsers': 'users/loadUsers',
        }),
        uploadImages(files) {
            if (files.length > 0) this.images = files;
        },
        uploadImagesRemote(files) {
            if (files.length > 0) this.remoteImages = files;
        },
        savedMedia(files) {
            if (files.length > 0) this.savedImages = files;
        },
        deletedMedia(files) {
            if (files.length > 0) this.deletedImages = [...files];
        },
        userOnLoad() {
            if (this.user) {
                return this.user;
            }
        },
        onDecode(code) {
            this.serial = code;
            this.$store.commit('settings/setScanner', false);
        },
        onSearchUser(search, loading) {
            if (search.length) {
                loading(true);
                this.search(search, loading, this);
            }
        },
        search: _.debounce((search, loading, vm) => {
            axios.post('/users', {
                searchTerm: search
            }).then((res) => {
                vm.options = res.data.data.users;
                loading(false);
            });
        }, 350),
        todayDate() {
            let today = new Date();
            let todayYear = today.getFullYear();
            let todayMonth = today.getMonth() < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1;
            let todayDay = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
            return todayYear+'-'+todayMonth+'-'+todayDay;
        },
        disabledAfterToday(date){
            const today = new Date();

            return date > today;
        },
        submitNew() {
            let media = this.images.concat(this.remoteImages);
            const formData = {
                pickup_date: this.pickup_date,
                user: this.user,
                model: this.model,
                serial: this.serial,
                images: media,
                defect_reported: this.defect_reported,
                defect_detected: this.defect_detected,
                note: this.note,
                passcode: this.passcode,
                pattern: this.pattern,
                warranty: this.warranty,
                intervention: this.intervention,
                price: this.price,
                result: this.result,
                status: this.status,
            }
            this.pushNewProductTemp(formData);
            this.clearForm();
        },
        submitUpdate() {
            this.setLoading(true);
            let media = this.images.concat(this.remoteImages);
            const formData = {
                id: this.productId,
                pickup_date: this.pickup_date,
                user_id: this.$store.getters['products/getUserSelected'].id,
                model: this.model,
                serial: this.serial,
                images: media,
                deleted_images: this.deletedImages,
                defect_reported: this.defect_reported,
                defect_detected: this.defect_detected,
                note: this.note,
                passcode: this.passcode,
                pattern: this.pattern,
                warranty: this.warranty,
                intervention: this.intervention,
                price: this.price,
                result_id: this.result.code ?? this.result.key,
                status_id: this.status.code ?? this.status.key,
            }
            this.updateProduct(formData)
                .then(() => {
                    this.$toast.success(this.$t('components.product.updated'));
                    this.$router.push('/products');
                    this.setLoading(false);
                })
                .catch((err) => console.log(err));
        },
        clearForm() {
            this.pickup_date = this.todayDate();
            this.model = '';
            this.serial = '';
            if (this.products.length == 0) {
                this.user = null;
                this.userUI = null;
                this.$store.dispatch('products/updateUserSelected', null);
            }

            this.images = [],
            this.remoteImages = [],
            EventBus.$emit('clearMedia');

            this.defect_reported = '';
            this.defect_detected = '';
            this.note = '';
            this.passcode = '';
            this.pattern = false;
            this.warranty = false;
            this.intervention = '';
            this.price = '';
            this.result = {
                label: "In laboratorio",
                key: 5,
                color: "info"
            };
            this.status = {
                label: "Non consegnato",
                key: 1,
                color: "danger"
            };
        },
        openModalUser() {
            this.$modal.show('modalUser');
        },
        adjustPrice(price) {
            let val = price.slice(-1);
            if (val == "," || val == ".") {
                this.price = price.slice(0,-1);
            }
        },
    },
    created() {
        this.clearForm();
        if (this.$route.params.id) {
            this.setLoading(true);
            let id = parseInt(this.$route.params.id);
            if (!Number.isInteger(id)) {
                this.$router.push('/not-found');
            }

            this.buttonNewUser = false;
            this.productId = id;
            this.loadProduct(this.productId).then(() => {
                this.product = this.$store.getters['products/editProduct'];
                this.$store.dispatch('products/updateUserSelected', this.product.user);
                this.user = this.product.user;
                this.pickup_date = this.product.pickup_date;
                this.model = this.product.model;
                this.serial = this.product.serial;
                this.defect_reported = this.product.defect_reported;
                this.defect_detected = this.product.defect_detected;
                this.note = this.product.note;
                if (this.product.passcode) {
                    this.passcode = this.product.passcode;
                    this.passcodeCheck = true;
                }
                this.pattern = this.product.pattern;
                this.warranty = this.product.warranty;
                this.intervention = this.product.intervention;
                this.price = this.product.price;
                this.result = {
                    label: this.product.result.name,
                    key: this.product.result.id,
                    color: this.product.result.color,
                };
                this.status = {
                    label: this.product.status.name,
                    key: this.product.status.id,
                    color: this.product.status.color,
                };
                this.setLoading(false);
            });
        }

        EventBus.$on('closeModal', (user) => {
            this.user = user;
            this.$store.dispatch('products/updateUserSelected', user);
            this.$modal.hide('modalUser');
        });
    },
    mounted() {
        this.loadResults();
        this.loadStatuses();

        this.result = {
            label: "In laboratorio",
            key: 5,
            color: "info"
        };
        this.status = {
            label: "Non consegnato",
            key: 1,
            color: "danger"
        };

        window.Echo.channel('products')
            .listen('.capturedImage', (e) => {
                if (this.User.id == e.user_id) {
                    this.remoteImages.push(e.image);
                }
            });
    },
    watch: {
        price(val) {
            let newVal = val.replace(/([^0-9,.])+([,.a-zA-Z+$]{0,})/g, "");
            newVal = newVal.replace(/([,.]{2,}$)/g, "");
            this.price = newVal;
        },
        '$store.state.products.userSelected': function() {
            this.user = this.$store.getters['products/getUserSelected'];
        }
    }

}
</script>