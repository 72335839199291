<template>
    <div class="row mb-4">
        <div class="col-md-12">
            <div class="card mb-3">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <label for="name" class="form-label">{{ $t('components.user.name') }} <span class="badge badge-danger">{{ $t('components.required') }}</span></label>
                            <input type="text" class="form-control" :class="{ 'is-invalid': $v.name.$dirty && $v.name.$invalid }" @input="$v.name.$touch()" id="name" v-model="name">
                            <div v-if="$v.name.$dirty && !$v.name.required" class="invalid-feedback">
                                {{ $t('error.name') }}
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for="email" class="form-label">{{ $t('components.user.email') }}</label>
                            <input type="email" class="form-control" :class="{ 'is-invalid': $v.email.$dirty && $v.email.$invalid }" @input="$v.email.$touch()" id="email" v-model="email">
                            <div v-if="$v.email.$dirty && !$v.email.email" class="invalid-feedback">
                                {{ $t('error.email_not_valid') }}
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6">
                            <label for="tel" class="form-label">{{ $t('components.user.phone') }}</label>
                            <input type="text" class="form-control" id="tel" v-model="tel">
                        </div>
                        <div class="col-md-6">
                            <label for="cell" class="form-label">{{ $t('components.user.cell') }}</label>
                            <input type="text" class="form-control" id="cell" v-model="cell">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-4">
                            <label for="username" class="form-label">{{ $t('components.user.username') }}</label>
                            <input type="text" class="form-control" id="username" v-model="username">
                        </div>
                        <div class="col-md-4">
                            <label for="password" class="form-label">{{ $t('components.user.password') }}</label>
                            <input type="password" class="form-control" id="password" v-model="password" :class="{ 'is-invalid': $v.password.$dirty && !$v.password.minLength }" @input="$v.password.$touch">
                            <div v-if="$v.password.$dirty && !$v.password.minLength" class="invalid-feedback">
                                {{ $t('error.password_min_length', { minLength: $v.password.$params.minLength.min }) }}
                            </div>
                        </div>
                        <div class="col-md-4" v-if="isAdmin">
                            <label for="role" class="form-label">{{ $t('components.user.role') }} <span class="badge badge-danger">{{ $t('components.required') }}</span></label>
                            <vselect :items="roleSelect" :options="roleSelect"  :class="{ 'is-invalid': $v.role.$dirty && $v.role.$invalid }" item-text="text" item-value="key" v-model="role" id="role" @input="$v.role.$touch" placeholder="Ruolo utente..."></vselect>
                            <div v-if="$v.role.$dirty && !$v.role.required" class="invalid-feedback">
                                {{ $t('error.role_required') }}
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <button class="btn btn-warning btn-block" @click.prevent="submitUpdate" :disabled="$v.$invalid">{{ $t('components.profile.update_button') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import { required, email, minLength } from 'vuelidate/lib/validators'

export default {
    name: 'user-form',
    data() {
        return {
            role: null,
            id: null,
            name: '',
            email: '',
            username: '',
            password: '',
            tel: '',
            cell: '',
        }
    },
    validations: {
        name: { required },
        email: { email },
        role: { required },
        password: { minLength: minLength(6) }
    },
    computed: {
        ...mapGetters({
            'roles': 'users/allRoles',
        }),
        roleSelect() {
            var formatted = [];
            for (let i = 0; i < this.roles.length;i++) {
                formatted[i] = {
                    label: this.roles[i].name,
                    code: this.roles[i].id
                }
            }
            return formatted;
        }
    },
    methods: {
        ...mapActions({
            'profile': 'users/profile',
            'loadRoles': 'users/loadRoles',
            'updateProfile': 'users/updateProfile',
        }),
        submitUpdate() {
            const formData = {
                id: this.id,
                name: this.name,
                email: this.email,
                role: this.role,
                username: this.username,
                tel: this.tel,
                cell: this.cell
            };
            if (this.password != "") {
                formData.password = this.password;
            }
            this.updateProfile(formData);
            this.$router.push('/');
        }
    },
    created() {
        this.loadRoles();

        this.profile().then((res) => {
            let user = res.data.data;

            this.id = user.id;
            this.name = user.name;
            this.email = user.email;
            this.username = user.username;
            this.tel = user.tel;
            this.cell = user.cell;
            this.role = user.role.name;
        });
    }
}
</script>