import Vue from 'vue'
import VueRouter from 'vue-router'
// pages
import HomeView from '../views/HomeView.vue'
import ProductsView from '../views/ProductsView.vue'
import UsersView from '../views/UsersView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import PublicProductView from '../views/PublicProductView.vue'
import ProductCamera from '../views/ProductCamera.vue'

// COMPONENTS
// products
import TableProduct from '@/components/Product/TableProduct'
import ProductForm from '@/components/Product/ProductForm'

// users
import TableUser from '@/components/User/TableUser'
import UserForm from '@/components/User/UserForm'

// auth
import Login from '@/components/Auth/Login'
import EditProfile from '@/components/User/EditProfile'

import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home',
    },
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAuth']) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: EditProfile,
    meta: {
      title: 'Profilo utente',
    },
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAuth']) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/product/qrcode/:qrcode',
    name: 'public_product',
    component: PublicProductView,
    meta: {
      title: 'Informazioni prodotto'
    }
  },
  {
    path: '/product/capture/image/:user_id/:product_id?',
    name: 'capture_image',
    component: ProductCamera,
    meta: {
      title: 'Scansione foto',
    }
  },
  {
    path: '/products',
    component: ProductsView,
    children: [
      {
        path: '',
        component: TableProduct,
        meta: {
          title: 'Lista riparazioni'
        }
      },
      {
        path: 'new',
        component: ProductForm,
        meta: {
          title: 'Nuova riparazione',
        }
      },
      {
        path: ':id',
        component: ProductForm,
        meta: {
          title: 'Modifica riparazione'
        }
      },
    ],
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAuth']) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/users',
    component: UsersView,
    children: [
      {
        path: '',
        component: TableUser,
        meta: {
          title: 'Lista clienti'
        }
      },
      {
        path: 'new',
        component: UserForm,
        meta: {
          title: 'Nuovo cliente'
        }
      },
      {
        path: ':id',
        component: UserForm,
        meta: {
          title: 'Modifica cliente'
        }
      },
    ],
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isAuth'] && store.getters['auth/isAdmin']) {
        next();
      } else {
        next('/login');
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login'
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/isAuth']) {
        next();
      }
    }
  },
  {
    path: '*',
    name: 'not-found',
    meta: {
      title: 'Not Found',
    },
    component: NotFoundView
  }
]

const router = new VueRouter({
  routes,
  mode: "history"
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title + ' - ' + process.env.VUE_APP_TITLE || process.env.VUE_APP_TITLE;
  });
});

export default router
