import axios from 'axios'

const state = {
    products: {
        data: [],
        totalRecords: 0
    },
    isLoading: false,
    editingProduct: null,
    newProductsTemp: [],
    results: [],
    statuses: [],
    serverParams: {
        columnFilters: {},
        searchTerm: '',
        searchUserTerm: '',
        sort: [
            {
                field: '',
                type: '',
            }
        ],
        page: parseInt(localStorage.getItem("currentPage")) || 1,
        perPage: parseInt(localStorage.getItem("productsPerPage")) || 50,
        result: JSON.parse(localStorage.getItem("resultSelect")) || [],
        status: JSON.parse(localStorage.getItem("statusSelect")) || [],
    },
    userSelected: null,
};

const getters = {
    getProducts: state => state.products,
    getServerParams: state => state.serverParams,
    isLoading: state => state.isLoading,
    editProduct: state => state.editingProduct,
    getNewProductsTemp: state => state.newProductsTemp,
    loadResultsSelect(state) {
        var formatted = [];
        for (let i = 0; i < state.results.length;i++) {
            formatted[i] = {
                label: state.results[i].name,
                code: state.results[i].id,
                color: state.results[i].color,
            }
        }
        return formatted;
    },
    loadStatusesSelect(state) {
        var formatted = [];
        for (let i = 0; i < state.statuses.length;i++) {
            formatted[i] = {
                label: state.statuses[i].name,
                code: state.statuses[i].id,
                color: state.statuses[i].color,
            }
        }
        return formatted;
    },
    getResults: state => state.results,
    getStatuses: state => state.statuses,
    getUserSelected: state => state.userSelected,
};

const actions = {
    loadProduct({commit}, productId) {
        return new Promise((resolve, reject) => {
            axios.post('/products/find/'+productId)
            .then(res => {
                if (res.data.success) {
                    commit('setProductEditing', res.data.data);
                }
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    },
    loadPublicProduct({commit}, productId) {
        return new Promise((resolve, reject) => {
            axios.get('/products/public/find/'+productId)
            .then(res => {
                if (res.data.success) {
                    commit('setProductEditing', res.data.data);
                }
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    },
    newProducts(_, prods = null) {
        var products;
        if (prods) {
            products = prods;
        } else {
            products = state.newProductsTemp;
        }
        return new Promise((resolve, reject) => {
            axios.post('/products/create', {products: products ?? state.newProductsTemp})
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
        });
    },
    updateProduct(_, formData) {
        return new Promise((resolve, reject) => {
            axios.patch('/products/update/'+formData.id, formData)
                .then(res => resolve(res))
                .catch(err => reject(err));
        });
    },
    updateProductResult(_, formData) {
        return new Promise((resolve, reject) => {
            axios.patch('/products/'+formData.product_id+'/result/'+formData.result_id)
                .then(res => resolve(res))
                .catch(err => reject(err));
        });
    },
    updateProductStatus(_, formData) {
        return new Promise((resolve, reject) => {
            axios.patch('/products/'+formData.product_id+'/status/'+formData.status_id)
                .then(res => resolve(res))
                .catch(err => reject(err));
        });
    },
    deleteProduct(_, productId) {
        return new Promise((resolve, reject) => {
            axios.delete('/products/delete/'+productId)
            .then(response => resolve(response))
            .catch(error => reject(error));
        });
    },
    pushNewProductTemp({commit}, payload) {
        commit('pushNewProductTemp', payload);
    },
    deleteProductTemp({commit}, product) {
        commit('deleteFromProductsTemp', product);
    },
    emptyProductTemp({commit}) {
        commit('emptyProductTemp');
    },
    loadResults({commit}) {
        axios.post('/settings/results')
            .then(res => {
                commit('setResults', res.data.data.results);
            })
            .catch(err => {
                console.log(err);
            });
    },
    loadStatuses({commit}) {
        axios.post('/settings/statuses')
            .then(res => {
                commit('setStatuses', res.data.data.statuses);
            })
            .catch(err => {
                console.log(err);
            });
    },
    loadProducts({commit, state}) {
        commit('setLoading');
        return new Promise((resolve, reject) => {
            axios.post('/products', state.serverParams)
            .then(res => {
                    commit('setProducts', {
                        products: res.data.data.products,
                        totalRecords: res.data.data.total,
                    });
                    commit('setLoading');
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject();
                });
        });
    },
    updateUserSelected({commit}, user) {
        commit('setUserSelected', user);
    }
};

const mutations = {
    setProducts(state, payload) {
        state.products.data = payload.products;
        state.products.totalRecords = payload.totalRecords;
    },
    updateParams(state, newProps) {
        state.serverParams = Object.assign({}, state.serverParams, newProps);
    },
    setProductEditing(state, product) {
        state.editingProduct = product;
    },
    pushNewProductTemp(state, product) {
        state.newProductsTemp.push(product);
    },
    deleteFromProductsTemp(state, product) {
        state.newProductsTemp.splice(state.newProductsTemp.indexOf(product), 1);
    },
    emptyProductTemp(state) {
        state.newProductsTemp = [];
    },
    setResults(state, results) {
        state.results = results;
    },
    setStatuses(state, statuses) {
        state.statuses = statuses;
    },
    setLoading(state) {
        state.isLoading = !state.isLoading;
    },
    setUserSelected(state, user) {
        state.userSelected = user;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}