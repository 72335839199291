<template>
    <div class="input-group" :class="classStyle">
        <Keypress key-event="keyup" :key-code="53" @success="inputFocus" @modifiers="['ctrlKey']" />
        <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"><i class="i-Bar-Code"></i></span>
        </div>
        <input type="text" class="form-control py-4 bg-white" v-model="search" @keyup.enter="searchSubmit" ref="barcode" :placeholder="$t('placeholder.barcode_search')" aria-label="Barcode" aria-describedby="basic-addon1">
    </div>
</template>
<script>
import Keypress from 'vue-keypress'

export default {
    name: 'barcodesearch',
    data() {
        return {
            search: '',
        }
    },
    props: {
        classStyle: {
            type: String,
            required: false
        }
    },
    components: {
        Keypress,
    },
    methods: {
        searchSubmit() {
            if (this.search == "" || (isNaN(this.search))) {
                this.$toast.error('Prodotto non trovato');
                return;
            }
            this.$store.dispatch('products/loadProduct', this.search).then((res) => {
                if (res.data.success) {
                    this.$router.push('/products/'+res.data.data.id);
                    this.$toast.success('Prodotto trovato');
                }
            });
        },
        inputFocus() {
            this.$refs.barcode.focus();
        },
    },
    mounted() {
        this.inputFocus();
    },
}
</script>