<template>
    <div class="dropdown">
        <div class="badge-top-container" role="button" id="dropdownNotification" @input="resetNotifications" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span class="badge badge-primary">{{ notifications.length }}</span>
            <i class="i-Bell text-muted header-icon"></i>
        </div>
        <!-- Notification dropdown -->
        <div v-if="notifications.length > 0" class="dropdown-menu dropdown-menu-right notification-dropdown rtl-ps-none" aria-labelledby="dropdownNotification" data-perfect-scrollbar data-suppress-scroll-x="true">
            <div class="dropdown-item d-flex" v-for="notification in notifications" :key="notification.id" @click="markRead(notification)">
                <div class="notification-icon">
                    <i class="i-Receipt-3 text-primary mr-1"></i>
                </div>
                <div class="notification-details flex-grow-1">
                    <p class="m-0 d-flex align-items-center">
                        <span>{{ notification.title }}</span>
                        <span class="badge badge-pill badge-danger ml-1 mr-1" v-if="!notification.read">{{ $t('components.notification.unread') }}</span>
                        <span class="flex-grow-1"></span>
                        <span class="text-small text-muted ml-auto">{{ notification.time | moment("from", "now") }}</span>
                    </p>
                    <p class="text-small text-muted m-0">
                        <span v-for="prod in notification.products" :key="prod.id">{{ prod.model }}</span>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-right my-2">
                    <a href="#" @click.prevent="markReadAll">{{ $t('components.notification.mark_all_read') }}</a> | <a href="#" @click.prevent="clear">{{ $t('components.notification.delete_all') }}</a>
                </div>
            </div>
        </div>
        <div v-else class="dropdown-menu dropdown-menu-right notification-dropdown rtl-ps-none" aria-labelledby="dropdownNotification" data-perfect-scrollbar data-suppress-scroll-x="true">
            <div class="dropdown-item d-flex">
                <div class="notification-details flex-grow-1">
                    <p class="text-small text-muted m-0">{{ $t('components.notification.no_notifications') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'notifications',
    computed: {
        ...mapGetters({
            'notifications': 'notifications/getNotifications',
        }),
    },
    methods: {
        ...mapActions({
            'resetNotifications': 'notifications/resetNotifications',
            'clearAll': 'notifications/clearAll',
            'markRead': 'notifications/markRead',
            'markReadAll': 'notifications/markReadAll',
        }),
        clear() {
            this.clearAll();
        }
    }
}
</script>